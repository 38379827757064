import {tabs} from './router';
import React, {useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Grow} from 'shared/ui/Grow';
import styled from 'styled-components';
import {device} from 'shared/device';
import {MenuItem} from 'shared/ui/MenuItem';
import {BtnWrapper, OpenButton} from './ui/OpenButton';
import {Flex} from 'shared/ui/Flex';
import {Res, useColors, useEmployeePermission} from 'shared/lib/hooks';
import {useAppSelector} from 'shared/store';
import {useQuery} from '@apollo/client';
import {Skeleton} from 'shared/ui/Skeleton';
import {ProgressIndicator} from 'shared/ui/TutorialCircleIndicator';
import {SIDEBAR_QUERY} from 'entities/sidebar';
import {Document} from 'shared/icons/Document';
import {UserTriple} from 'shared/icons/UserTriple';
import {Plain} from 'shared/icons/Plain';
import {UserDouble} from 'shared/icons/UserDouble';
import {Chart} from 'shared/icons/Chart';
import {Bookmark} from 'shared/icons/Bookmark';
import {Coupon} from 'shared/icons/Coupon';
import {Layers} from 'shared/icons/Layers';
import {Wallet} from 'shared/icons/Wallet';
import {Setting} from 'shared/icons/Setting';
import {Education} from 'shared/icons/Education';
import {Support} from 'shared/icons/Support';
import {Magic} from 'shared/icons/Magic';
import {ArrowDown1v} from 'shared/icons/ArrowDown1v';
import {Bell} from 'shared/icons/Bell';
import {Text} from 'shared/ui/Text';

type SubTabs = {
  path: string;
  title: string;
  username?: boolean;
  permission?: keyof Res;
};

const Wrapper = styled.div<{open?: boolean}>`
  @media ${device.mobile} {
    display: none;
  }
  position: sticky;
  top: 0;
  z-index: 100;
  width: ${({open}) => (open ? '258px' : '88px')};
  height: 100dvh;
  background-color: ${({theme}) => theme.bgPrimary};
  transition: width 300ms ease-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover ${BtnWrapper} {
    opacity: 1;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollView = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100dvh;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const RowPopover = styled.div`
  opacity: 0;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: hidden;
  width: 0;
  left: 88px;
  transition:
    opacity 100ms,
    width 300ms;
  ${({theme}) => theme.dropShadow}
`;

const SidebarPopover = styled.div<{
  background: string;
  color: string;
}>`
  background-color: ${({background}) => background};
  padding: 12px 8px;
  border-radius: 8px;
  width: max-content;
  color: ${({color}) => color};
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 20px;
  white-space: nowrap;
`;

const SubmenuWrapper = styled.div<{y?: number | null}>`
  width: 258px;
  background-color: ${({theme}) => theme.bgPrimary};
  position: fixed;
  left: 88px;
  z-index: 100;
  visibility: hidden;
  top: ${({y}) => y}px;
  border-radius: 0 24px 24px 0px;
  ${({theme}) => theme.dropShadow}
`;

const Label = styled.div<{open?: boolean; active: boolean}>`
  color: ${({theme, active}) =>
    active ? theme.mainPrimary : theme.textTertiary};
  overflow: hidden;
  white-space: nowrap;
  transition: width 300ms ease;
  font-size: 16px;
  width: ${({open}) => (open ? 'none' : '0')};
  pointer-events: none;
  user-select: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-overflow: ellipsis;
`;

const Row = styled.div<{
  open?: boolean;
  type: 'simple' | 'group';
  active?: boolean;
  popover?: number;
}>`
  position: relative;
  height: 56px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding-left: ${({open}) => (open ? '20px' : '28px')};
  padding-right: 20px;
  width: 258px;
  transition: padding-left 300ms ease-out;
  background-color: ${({active, theme}) =>
    active ? theme.mainLight : theme.bgPrimary};
  gap: 16px;
  pointer-events: all;
  &:hover {
    background-color: ${({theme}) => theme.mainLight};
    border-color: ${({theme, active}) =>
      active ? theme.mainPrimary : theme.mainLight};
    color: ${({theme}) => theme.mainPrimary};
  }
  &:hover ${Label} {
    color: ${({theme}) => theme.mainPrimary};
  }
  &:hover > div {
    color: ${({theme}) => theme.mainPrimary};
    visibility: visible;
  }
  &:hover ${SubmenuWrapper} {
    left: ${({open}) => (open ? '258px' : '88px')};
  }
  &:hover ${RowPopover} {
    opacity: ${({type, open}) => (type === 'simple' && open == false ? 1 : 0)};
    left: 88px;
    width: ${({open, popover}) =>
      open === false ? `${(popover ?? 0) + 7}px` : '7px'};
  }
  > div {
    color: ${({active, theme}) =>
      active ? theme.mainPrimary : theme.textTertiary};
  }
  cursor: pointer;
  border-style: solid;
  border-width: 0 0 0 4px;
  border-color: ${({active, theme}) =>
    active ? theme.mainPrimary : theme.bgPrimary};
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
  color: ${({theme}) => theme.textTertiary};
`;

const TutorialIcon = styled.div`
  width: 40px;
  height: 40px;
`;

const Subtab = styled.div<{active?: boolean}>`
  height: 56px;
  padding: 16px 24px 16px 24px;
  width: 100%;
  color: ${({active, theme}) =>
    active ? theme.mainPrimary : theme.textTertiary};
  &:hover {
    color: ${({theme}) => theme.mainPrimary};
  }
`;

const AccordionContent = styled.div<{isOpen: boolean}>`
  max-height: ${({isOpen}) => (isOpen ? '500px' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease;
`;

const isDev = process.env.REACT_APP_DOMAIN?.includes('dev');

const customersTabs: SubTabs[] = [
  {
    path: ':username/customers',
    title: 'Клиенты',
    username: true,
  },
  {
    path: ':username/tags',
    title: 'Теги клиентов',
    username: true,
  },
];

const employeesTabs: SubTabs[] = isDev
  ? [
      {
        path: ':username/employees',
        title: 'Сотрудники',
        username: true,
      },
      {
        path: ':username/schedule',
        title: 'График работы',
        username: true,
        permission: 'work_schedule_info_permission',
      },
      {
        path: ':username/salary',
        title: 'Зарплаты',
        username: true,
        permission: 'salaries_permission',
      },
    ]
  : [
      {
        path: ':username/employees',
        title: 'Сотрудники',
        username: true,
      },
      {
        path: ':username/schedule',
        title: 'График работы',
        username: true,
        permission: 'work_schedule_info_permission',
      },
    ];
const analyticsTabs: SubTabs[] = [
  {
    path: ':username/analytics',
    title: 'Основные показатели',
    username: true,
  },
  {
    path: ':username/statistics',
    title: 'Отчеты',
    username: true,
  },
];
const catalogTabs: SubTabs[] = [
  {
    path: ':username/services',
    title: 'Услуги',
    username: true,
    permission: 'view_service_permission',
  },
  {
    path: ':username/products',
    title: 'Товары',
    username: true,
    permission: 'view_products_permission',
  },
  {
    path: ':username/resources',
    title: 'Ресурсы',
    username: true,
  },
];
const loyaltyTabs: SubTabs[] = [
  {
    path: ':username/bonuses',
    title: 'Бонусные карты',
    username: true,
    permission: 'edit_bonus_card_templates',
  },
  {
    path: ':username/certificates',
    title: 'Сертификаты',
    username: true,
    permission: 'edit_certificate_templates',
  },
  {
    path: ':username/discounts',
    title: 'Акции и скидки',
    username: true,
    permission: 'edit_discounts_permission',
  },
  {
    path: ':username/reviews',
    title: 'Отзывы',
    username: true,
    permission: 'reviews_info_permission',
  },
];
const tariffTabs: SubTabs[] = [
  {
    path: ':username/my-tariff',
    title: 'Мой тариф',
    username: true,
  },
  {
    path: ':username/payments-history',
    title: 'История платежей',
    username: true,
  },
  {
    path: ':username/tariff-plans',
    title: 'Тарифные планы',
    username: true,
  },
];

export function Sidebar() {
  const [open, setOpen] = useState(
    JSON.parse(localStorage.getItem('sidebar_opened') ?? 'false'),
  );
  const colors = useColors();
  const navigate = useNavigate();
  const location = useLocation();
  const refPopover = useRef<HTMLDivElement>(null);
  const username = useAppSelector(state => state.company.data?.username);
  const companyId = useAppSelector(state => state.company.data?.id);
  const {loading, data} = useQuery(SIDEBAR_QUERY, {
    variables: {companyId: companyId!},
    skip: !companyId,
  });
  const permissions = useEmployeePermission(data);
  const initialPlan = !data?.company?.plan;

  const rules = data?.moderationsCheck;
  const employeeAdded = rules?.map(
    item => item?.is_employee_with_specialization_added,
  )[0];
  const companyInfo = rules?.map(item => item?.is_required_items_filled)[0];
  const scheduleAdded = rules?.map(item => item?.is_employee_added)[0];
  const serviceAdded = rules?.map(item => item?.is_service_added)[0];
  const combinedObject = {
    employeeAdded,
    companyInfo,
    scheduleAdded,
    serviceAdded,
  };
  const check = Object.values(combinedObject);
  const percent =
    (check.filter(item => item === true).length / check.length) * 100;
  const centerY = 50;
  const adjustedY = centerY + 30 / 3;
  const routeName =
    location.pathname.split('/')[location.pathname.split('/').length - 1];
  const routeNamesStatistics =
    location.pathname.split('/')[location.pathname?.split('/')?.length - 2];

  return (
    <Wrapper id="sidebar" open={open}>
      <OpenButton
        open={open}
        onChange={value => {
          if (loading) return;
          setOpen(value);
          localStorage.setItem('sidebar_opened', value ? 'true' : 'false');
        }}
      />
      <ScrollView id="SidebarScrollView">
        {loading ? (
          <>
            <MenuItem open={open} />
            <SideBarSkeleton open={open} />
          </>
        ) : (
          <>
            <Flex direction="column" gap={8}>
              <MenuItem open={open} />
              <Grow>
                <RowComponent
                  permissions={permissions}
                  active={location.pathname.includes('bookings')}
                  type={'simple'}
                  open={open}
                  title={'Записи'}
                  icon={<Document />}
                  onClick={() => {
                    navigate(username + '/bookings');
                  }}
                />
                {permissions.customers_info_permission ? (
                  <RowComponent
                    permissions={permissions}
                    active={customersTabs
                      .map(item => item.path.split('/')[1])
                      .includes(routeName)}
                    type={'group'}
                    open={open}
                    title={'Клиенты'}
                    icon={<UserTriple color="currentColor" />}
                    subtabs={customersTabs}
                    onClick={() => {
                      navigate(username + '/customers');
                    }}
                  />
                ) : null}
                {permissions.messages_permission ? (
                  <RowComponent
                    permissions={permissions}
                    active={location.pathname.includes('messages')}
                    type={'simple'}
                    open={open}
                    title="Рассылки"
                    icon={<Plain color="currentColor" />}
                    onClick={() => navigate(username + '/messages')}
                  />
                ) : null}
                {process.env.REACT_APP_DOMAIN?.includes('dev') ? (
                  <RowComponent
                    permissions={permissions}
                    active={['neuralList', 'neuralChat'].includes(routeName)}
                    type="simple"
                    open={open}
                    title="Нейросеть"
                    icon={<Magic color="currentColor" />}
                    onClick={() => navigate(username + '/neuralChat')}
                  />
                ) : null}
                <RowComponent
                  permissions={permissions}
                  active={employeesTabs
                    .map(item => item.path.split('/')[1])
                    .includes(routeName)}
                  type={'group'}
                  open={open}
                  title={'Сотрудники'}
                  icon={<UserDouble color="currentColor" />}
                  subtabs={employeesTabs}
                  onClick={() => {
                    navigate(username + '/employees');
                  }}
                />
                {permissions.reports_permission ? (
                  <RowComponent
                    permissions={permissions}
                    active={
                      analyticsTabs
                        .map(item => item.path.split('/')[1])
                        .includes(routeName) ||
                      routeNamesStatistics === 'statistics'
                    }
                    type={'group'}
                    open={open}
                    title={'Аналитика'}
                    icon={<Chart color="currentColor" />}
                    subtabs={analyticsTabs}
                    onClick={() => {
                      navigate(username + '/analytics');
                    }}
                  />
                ) : null}
                <RowComponent
                  permissions={permissions}
                  active={catalogTabs
                    .map(item => item.path.split('/')[1])
                    .includes(routeName)}
                  type={'group'}
                  open={open}
                  title={'Каталог'}
                  icon={<Bookmark color="currentColor" />}
                  subtabs={catalogTabs}
                  onClick={() => {
                    if (permissions.view_service_permission) {
                      navigate(username + '/services');
                      return;
                    }
                    if (permissions.view_products_permission) {
                      navigate(username + '/products');
                      return;
                    } else {
                      navigate(username + '/resources');
                    }
                  }}
                />
                {permissions.edit_bonus_card_templates ||
                permissions.edit_certificate_templates ||
                permissions.edit_discounts_permission ||
                permissions.reviews_info_permission ? (
                  <RowComponent
                    permissions={permissions}
                    active={loyaltyTabs
                      .map(item => item.path.split('/')[1])
                      .includes(routeName)}
                    type={'group'}
                    open={open}
                    title={'Лояльность'}
                    icon={<Coupon color="currentColor" />}
                    subtabs={loyaltyTabs}
                    onClick={() => {
                      if (permissions.edit_bonus_card_templates) {
                        navigate(username + '/bonuses');
                        return;
                      }
                      if (permissions.edit_certificate_templates) {
                        navigate(username + '/certificates');
                        return;
                      }
                      if (permissions.edit_discounts_permission) {
                        navigate(username + '/discounts');
                        return;
                      }
                      if (permissions.reviews_info_permission) {
                        navigate(username + '/reviews');
                        return;
                      }
                    }}
                  />
                ) : null}
                {permissions.edit_apps_permission ? (
                  <RowComponent
                    permissions={permissions}
                    active={location.pathname.includes('catalog-apps')}
                    type={'simple'}
                    open={open}
                    title={'Каталог приложений'}
                    icon={<Layers color="currentColor" />}
                    onClick={() => {
                      navigate(username + '/catalog-apps');
                    }}
                  />
                ) : null}
                {permissions.subscription_and_pay_permission ? (
                  <RowComponent
                    permissions={permissions}
                    active={location.pathname.includes('tariff-and-payment')}
                    type={initialPlan ? 'simple' : 'group'}
                    open={open}
                    title={'Тарифы и оплата'}
                    icon={<Wallet color="currentColor" />}
                    subtabs={tariffTabs}
                    onClick={() => {
                      if (initialPlan) {
                        navigate(username + '/tariff-plans');
                      } else {
                        navigate(username + '/my-tariff');
                      }
                    }}
                  />
                ) : null}

                <RowComponent
                  permissions={permissions}
                  active={location.pathname.includes('setting')}
                  type={'simple'}
                  open={open}
                  title={'Настройки'}
                  icon={<Setting color="currentColor" />}
                  onClick={() => {
                    navigate(username + '/setting');
                  }}
                />
              </Grow>
            </Flex>
            <Grow style={{justifyContent: 'end'}}>
              <div>
                <Row
                  style={{
                    paddingLeft:
                      percent !== 100 ? (open ? 12 : 20) : open ? 20 : 28,
                  }}
                  active={location.pathname.includes('tutorial')}
                  type={'simple'}
                  popover={refPopover.current?.offsetWidth ?? 120}
                  onClick={() => navigate(username + '/tutorial')}
                  open={open}>
                  {percent !== 100 ? (
                    <TutorialIcon>
                      <ProgressIndicator
                        percent={percent}
                        adjustedY={adjustedY}
                      />
                    </TutorialIcon>
                  ) : (
                    <Icon>
                      <Education color="currentColor" />
                    </Icon>
                  )}
                  <Label
                    active={location.pathname.includes('tutorial')}
                    open={open}>
                    Обучение
                  </Label>
                  <RowPopover>
                    <svg
                      width="7"
                      height="10"
                      viewBox="0 0 7 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1.71359 6.696C0.460258 5.91266 0.460258 4.08734 1.71359 3.304L7 -3.49691e-07L7 10L1.71359 6.696Z"
                        fill={colors.sidebarTooltip}
                      />
                    </svg>
                    <SidebarPopover
                      ref={refPopover}
                      background={colors.sidebarTooltip}
                      color={colors.textPrimary}>
                      Обучение
                    </SidebarPopover>
                  </RowPopover>
                </Row>
                <Row
                  active={location.pathname.includes('notification')}
                  type={'simple'}
                  popover={refPopover.current?.offsetWidth ?? 140}
                  onClick={() => navigate(username + '/notification/booking')}
                  open={open}>
                  <Icon style={{position: 'relative'}}>
                    <Text
                      style={{
                        position: 'absolute',
                        top: -5,
                        right: -5,
                        backgroundColor: colors.mainPrimary,
                        padding: '2px 4px',
                        borderRadius: '33px',
                        fontSize: 10,
                        lineHeight: '12px',
                      }}>
                      {data?.me?.unviewed_notifications ?? 0}
                    </Text>
                    <Bell color="currentColor" />
                  </Icon>

                  <Label
                    active={location.pathname.includes('tutorial')}
                    open={open}>
                    Уведомление
                  </Label>
                  <RowPopover>
                    <svg
                      width="7"
                      height="10"
                      viewBox="0 0 7 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1.71359 6.696C0.460258 5.91266 0.460258 4.08734 1.71359 3.304L7 -3.49691e-07L7 10L1.71359 6.696Z"
                        fill={colors.sidebarTooltip}
                      />
                    </svg>
                    <SidebarPopover
                      ref={refPopover}
                      background={colors.sidebarTooltip}
                      color={colors.textPrimary}>
                      Уведомление
                    </SidebarPopover>
                  </RowPopover>
                </Row>
                <Row
                  active={location.pathname.includes('support')}
                  type={'simple'}
                  popover={refPopover.current?.offsetWidth ?? 120}
                  onClick={() => navigate('/support')}
                  open={open}>
                  <Icon>
                    <Support color="currentColor" />
                  </Icon>
                  <Label
                    active={location.pathname.includes('tutorial')}
                    open={open}>
                    Центр помощи
                  </Label>
                  <RowPopover>
                    <svg
                      width="7"
                      height="10"
                      viewBox="0 0 7 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1.71359 6.696C0.460258 5.91266 0.460258 4.08734 1.71359 3.304L7 -3.49691e-07L7 10L1.71359 6.696Z"
                        fill={colors.sidebarTooltip}
                      />
                    </svg>
                    <SidebarPopover
                      ref={refPopover}
                      background={colors.sidebarTooltip}
                      color={colors.textPrimary}>
                      Центр помощи
                    </SidebarPopover>
                  </RowPopover>
                </Row>
              </div>
            </Grow>
          </>
        )}
      </ScrollView>
    </Wrapper>
  );
}

const RowComponent = ({
  active,
  type,
  open,
  title,
  icon,
  subtabs,
  onClick,
  permissions,
}: {
  permissions: Res;
  active: boolean;
  type: 'simple' | 'group';
  open: boolean;
  title: string;
  icon?: React.JSX.Element;
  subtabs?: {
    path: string;
    title: string;
    username?: boolean;
    permission?: keyof Res;
  }[];
  onClick?: () => void;
}) => {
  const [position, setPosition] = useState<{
    y: number | null;
  }>({
    y: null,
  });
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const colors = useColors();
  const refPopover = useRef<HTMLDivElement>(null);
  const username = useAppSelector(state => state.company.data?.username);

  return (
    <>
      <Row
        active={active}
        type={type}
        onClick={() => {
          if ((type !== 'group' || !open) && onClick) {
            return onClick();
          }
          setShow(value => !value);
        }}
        onMouseMoveCapture={event => {
          const sidebarScrollView =
            document.getElementById('SidebarScrollView');
          setPosition({
            y:
              event.currentTarget.offsetTop -
              (sidebarScrollView?.scrollTop ?? 0),
          });
        }}
        popover={refPopover.current?.offsetWidth ?? 0}
        open={open}
        key={title}>
        {icon ? <Icon>{icon}</Icon> : null}
        <Label active={active} open={open}>
          {title}
        </Label>
        {type === 'group' ? (
          <Flex flex={1} style={{justifyContent: 'end'}}>
            <ArrowDown1v
              color={colors.textTertiary}
              style={{
                transform: `rotate(${show ? '180deg' : '0'})`,
                transition: 'transform 0.3s',
              }}
            />
          </Flex>
        ) : null}

        {type === 'group' && !open ? (
          <SubmenuWrapper {...position}>
            {subtabs?.map(subtab => {
              const activeSubtab = location.pathname.endsWith(
                subtab.path.replace(':username', '/' + username),
              );
              const hide = subtab.permission && !permissions[subtab.permission];
              if (hide) return null;
              return (
                <Subtab
                  active={activeSubtab}
                  onClick={e => {
                    navigate(
                      subtab.username
                        ? subtab.path.replace(':username', username ?? '')
                        : subtab.path,
                    );
                    e.stopPropagation();
                  }}
                  key={subtab.title}>
                  {subtab.title}
                </Subtab>
              );
            })}
          </SubmenuWrapper>
        ) : null}

        <RowPopover>
          <svg
            width="7"
            height="10"
            viewBox="0 0 7 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.71359 6.696C0.460258 5.91266 0.460258 4.08734 1.71359 3.304L7 -3.49691e-07L7 10L1.71359 6.696Z"
              fill={colors.sidebarTooltip}
            />
          </svg>
          <SidebarPopover
            ref={refPopover}
            background={colors.sidebarTooltip}
            color={colors.textPrimary}>
            {title}
          </SidebarPopover>
        </RowPopover>
      </Row>

      {type === 'group' && open ? (
        <AccordionContent isOpen={!!show}>
          <Flex direction="column">
            {subtabs?.map(subtab => {
              const activeSubtab = location.pathname.startsWith(
                subtab.path.replace(':username', '/' + username),
              );
              const hide = subtab.permission && !permissions[subtab.permission];
              if (hide) return null;
              return (
                <Subtab
                  style={{marginLeft: 52, cursor: 'pointer'}}
                  active={activeSubtab}
                  onClick={e => {
                    navigate(
                      subtab.username
                        ? subtab.path.replace(':username', username ?? '')
                        : subtab.path,
                    );
                    e.stopPropagation();
                  }}
                  key={subtab.title}>
                  {subtab.title}
                </Subtab>
              );
            })}
          </Flex>
        </AccordionContent>
      ) : null}
    </>
  );
};

const SideBarSkeleton = ({open}: {open: boolean}) => {
  return (
    <Skeleton
      style={{marginTop: 16}}
      width={open ? 258 : 88}
      height={'calc(100dvh - 104px)'}>
      {tabs
        .filter(
          tab =>
            tab.place !== 'sidebar_bottom' &&
            tab.place !== 'unplaced' &&
            tab.place !== 'tabbar',
        )
        .map((_, index) => (
          <React.Fragment key={'sidebar_skeleton_' + index}>
            <rect
              width={24}
              height={24}
              rx={8}
              ry={8}
              x={30}
              y={16 + 64 * index}
            />
          </React.Fragment>
        ))}
    </Skeleton>
  );
};
