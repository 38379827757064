import React, {useRef, useState} from 'react';
import {useColors} from 'shared/lib/hooks';
import styled from 'styled-components';
import {Avatar} from 'shared/ui/Avatar';
import {Flex} from 'shared/ui/Flex';
import {Text} from 'shared/ui/Text';
import {mobileMediaQuery} from 'shared/lib/utils';
import {gql} from 'shared/__generated__';
import {useQuery} from '@apollo/client';
import {
  logoutThunk,
  setCompanyData,
  setThemeSlice,
  useAppDispatch,
  useAppSelector,
} from 'shared/store';
import {CirclePlus} from 'shared/icons/CirclePlus';
import {useNavigate} from 'react-router-dom';
import {Divider} from 'shared/ui/Divider';
import {Switch} from 'shared/ui/Switch';
import {Skeleton} from 'shared/ui/Skeleton';
import Accept from 'shared/icons/Accept';
import dayjs from 'dayjs';
import {CircleAlert} from 'shared/icons/CircleAlert';
import {ArrowRight1v} from 'shared/icons/ArrowRight1v';
import {ArrowDown1v} from 'shared/icons/ArrowDown1v';
import {Popup} from 'shared/ui/Popup';
import {CloseButton} from 'shared/ui/CloseButton';
import {Button} from 'shared/ui/Button';

const RowPopover = styled.div<{visible: boolean}>`
  opacity: 1;
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  width: 0;
  top: 72px;
  z-index: 100;
  left: 24px;
  visibility: ${({visible}) => (visible ? 'visible' : 'hidden')};
  transition:
    opacity 100ms,
    width 300ms;
  ${({theme}) => theme.dropShadow}
`;

const Head = styled.div`
  display: flex;
  padding: 28px 24px 16px 24px;
  ${mobileMediaQuery} {
    height: 56px;
    padding: 8px 24px 8px 24px;
    border-style: none;
  }
  gap: 8px;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: ${props => props.theme.borderPrimary};
  pointer-events: all;
  cursor: pointer;
`;

const HeadFlex = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  gap: 8px;
  overflow-x: hidden;
  width: max-content;
  &::-webkit-scrollbar {
    display: none;
  }
  &:hover ${RowPopover} {
    opacity: 1;
    width: initial;
  }
  pointer-events: all;
`;

const SidebarPopover = styled.div<{
  background: string;
  color: string;
}>`
  background-color: ${({background}) => background};
  padding: 12px 8px;
  border-radius: 8px;
  width: max-content;
  color: ${({color}) => color};
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 20px;
  white-space: nowrap;
`;

export const ME = gql(`
  query Me {
    me {
      id
      name
      surname
      employees {
        company {
          id
          name
          username
          avatar {
            id
            url_256
          }
          plan {
            id
            created_at
            updated_at
            slots
            type
            price
          }
          address
          subscription_end_date
        }
        id
        invite_status
        role
      }
      avatar {
        id
        url
        url_64
      }
      unviewed_notifications
    }
  }
`);

const Backdrop = styled.div<{visible: boolean}>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: transparent;
  display: ${({visible}) => (visible ? 'flex' : 'none')};
`;

const ButtonMenu = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  width: 100%;
  border: none;
  background-color: transparent;
  pointer-events: all;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Inter';
  color: ${({theme}) => theme.textPrimary};
  &:hover {
    background-color: ${({theme}) => theme.mainLight};
    color: ${({theme}) => theme.mainPrimary};
  }
`;

const ThemeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: all;
  cursor: pointer;
  padding: 0 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Inter';
  height: 40px;
  border: none;
  background-color: transparent;
  color: ${({theme}) => theme.textPrimary};
  &:hover {
    background-color: ${({theme}) => theme.mainLight};
    color: ${({theme}) => theme.mainPrimary};
  }
`;

export function MenuItem({open}: {open: boolean}) {
  const colors = useColors();
  const navigate = useNavigate();
  const theme = useAppSelector(state => state.theme.theme);
  const dispatch = useAppDispatch();
  const [menuVisible, setMenuVisible] = useState(false);
  const {data, loading} = useQuery(ME);
  const companyId = useAppSelector(state => state.company.data?.id);
  const userId = useAppSelector(state => state.auth.userId);
  const [quitModal, setQuitModal] = useState(false);
  const company = data?.me?.employees.find(
    employee => employee.company.id === companyId,
  )?.company;
  const companies =
    data?.me?.employees.map(item => ({
      id: item.company.id,
      title: item.company.name,
      avatar: item.company.avatar?.url_256 ?? undefined,
      address: item.company.address,
      employeeId: item.id,
      invite_status: item.invite_status,
      subscription_end_date: item.company.subscription_end_date,
      role: item.role,
      plan: item.company.plan,
      username: item.company.username,
    })) ?? [];
  const ref = useRef<HTMLDivElement>(null);

  return (
    <>
      <Head onClick={() => setMenuVisible(!menuVisible)}>
        <HeadFlex>
          <Avatar
            variant="company"
            size={40}
            url={company?.avatar?.url_256 ?? undefined}
          />
          <Flex direction="row" flex={1}>
            <Text
              typography="text16Semibold"
              style={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}>
              {company?.name}
            </Text>
          </Flex>
          <div
            style={{
              display: 'flex',
              transform: menuVisible ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 300ms',
              width: 24,
              height: 24,
            }}>
            <ArrowDown1v color={colors.textTertiary} />
          </div>
          <RowPopover visible={!open}>
            <svg
              width="12"
              height="7"
              viewBox="0 0 12 7"
              fill="none"
              style={{marginLeft: 15}}
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4.4 1.13333C5.2 0.066667 6.8 0.0666667 7.6 1.13333L12 7H0L4.4 1.13333Z"
                fill={colors.sidebarTooltip}
              />
            </svg>
            <SidebarPopover
              background={colors.sidebarTooltip}
              color={colors.textPrimary}>
              {company?.name}
            </SidebarPopover>
          </RowPopover>
        </HeadFlex>
      </Head>

      <Backdrop visible={menuVisible} onClick={() => setMenuVisible(false)} />
      <CustomModal visible={menuVisible} ref={ref}>
        {loading ? (
          <Skeleton height={500}>
            <circle r={16} cx={20} cy={20} />
            <rect width={160} height={24} x={44} y={8} rx={8} ry={8} />
            <circle r={16} cx={20} cy={68} />
            <rect width={80} height={24} x={44} y={56} rx={8} ry={8} />
            <circle r={16} cx={20} cy={116} />
            <rect width={110} height={24} x={44} y={104} rx={8} ry={8} />
            <circle r={12} cx={12} cy={156 + 8} />
            <rect width={180} height={20} y={154} x={40} rx={4} ry={4} />
            <rect width={242} height={1} y={192} />
            <rect width={180} height={20} y={211} rx={8} ry={8} />
            <rect width={24} height={24} y={209} x={234} rx={8} ry={8} />
            <rect width={120} height={20} y={261} rx={8} ry={8} />
            <rect width={24} height={24} y={259} x={234} rx={8} ry={8} />
            <rect width={242} height={1} y={299} />
            <circle r={16} cx={20} cy={327} />
            <rect width={216} height={20} x={44} y={317} rx={8} ry={8} />
            <rect width={150} height={20} x={0} y={365} rx={8} ry={8} />
            <rect width={120} height={20} x={0} y={413} rx={8} ry={8} />
          </Skeleton>
        ) : (
          <>
            <ScrollView>
              {companies
                .filter(
                  item =>
                    item.invite_status === 'accepted' ||
                    item.invite_status === 'pending',
                )
                .map(company => (
                  <ButtonMenu
                    onClick={() => {
                      if (company.invite_status === 'pending') {
                        navigate('/invite-company', {
                          state: {
                            user_id: userId ?? '',
                            company_id: company.id,
                          },
                        });
                      } else {
                        if (
                          dayjs().isAfter(
                            dayjs(company.subscription_end_date).format(
                              'YYYY-MM-DD',
                            ),
                            'date',
                          )
                        ) {
                          navigate('unavailable-company', {
                            state: {
                              role: company.role,
                              name: company.title,
                              plan: company.plan,
                              companyId: company.id,
                            },
                          });
                        } else {
                          dispatch(
                            setCompanyData({
                              id: company.id,
                              employeeId: company.employeeId,
                              username: company.username,
                            }),
                          );
                          navigate(`/${company.username}/bookings`);
                        }
                      }
                      setMenuVisible(false);
                    }}
                    key={company.id}>
                    <Avatar variant="company" url={company.avatar} size={32} />
                    <Flex
                      direction="column"
                      justifyContent="center"
                      style={{height: 48, pointerEvents: 'none'}}
                      gap={4}
                      flex={1}>
                      <Text
                        color={
                          companyId === company.id
                            ? 'mainPrimary'
                            : 'textPrimary'
                        }
                        style={{
                          pointerEvents: 'none',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          width: '204px',
                        }}>
                        {company.title}
                      </Text>
                      <Text
                        typography="subHead14Regular"
                        color={
                          company.invite_status === 'accepted'
                            ? 'textTertiary'
                            : 'successPrimary'
                        }>
                        {company.invite_status === 'accepted'
                          ? company.address
                          : 'Приглашение'}
                      </Text>
                    </Flex>
                    {companyId === company.id ? (
                      <Accept size={24} />
                    ) : dayjs().isAfter(
                        dayjs(company.subscription_end_date).format(
                          'YYYY-MM-DD',
                        ),
                        'date',
                      ) ? (
                      <CircleAlert />
                    ) : (
                      <></>
                    )}
                  </ButtonMenu>
                ))}
            </ScrollView>
            <ButtonMenu
              style={{height: 40}}
              onClick={() => navigate('/create-company')}>
              <CirclePlus /> Создать
            </ButtonMenu>
            <Divider />
            <ThemeButton
              onClick={() => {
                dispatch(setThemeSlice(theme === 'light' ? 'dark' : 'light'));
              }}>
              Темная тема
              <Switch value={theme === 'dark'} />
            </ThemeButton>
            <Divider mt={8} mb={8} />
            <ButtonMenu
              onClick={() => {
                navigate('/profile');
              }}>
              <Flex
                flex={1}
                alignItems="center"
                style={{width: 'calc(100% - 36px)'}}
                gap={8}>
                <Avatar
                  variant="avatar"
                  url={data?.me?.avatar?.url_64 ?? data?.me?.avatar?.url}
                  size={32}
                />
                <Text
                  typography="subHead14Medium"
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: '244px ',
                  }}>
                  {data?.me?.name} {data?.me?.surname}
                </Text>
              </Flex>
              <Flex style={{width: 24, height: 24}}>
                <ArrowRight1v color={colors.mainPrimary} size={24} />
              </Flex>
            </ButtonMenu>
            <ButtonMenu style={{height: 40}} onClick={() => setQuitModal(true)}>
              Выйти
            </ButtonMenu>
          </>
        )}
        <Popup
          visible={quitModal}
          onClose={() => setQuitModal(false)}
          style={{width: 343, alignItems: 'initial', padding: '24px 16px'}}>
          <Flex direction="column" gap={16}>
            <Flex flex={1} justifyContent="space-between" alignItems="center">
              <Text typography="title20" color="textPrimary">
                Выйти
              </Text>
              <CloseButton
                onClose={() => {
                  setQuitModal(false);
                }}
              />
            </Flex>
            <Text typography="subHead14Regular">
              Вы уверены, что хотите выйти?
            </Text>
            <Flex gap={8}>
              <Button
                size="medium"
                variant="outline"
                style={{flex: 1, borderColor: colors.mainPrimary}}
                onClick={() => {
                  setQuitModal(false);
                }}>
                Отмена
              </Button>
              <Button
                size="medium"
                variant="outline"
                onClick={() => {
                  dispatch(logoutThunk()).then(() => {
                    navigate('/');
                  });
                }}
                style={{
                  flex: 1,
                  borderColor: colors.dangerPrimary,
                  color: colors.dangerPrimary,
                }}>
                Выйти
              </Button>
            </Flex>
          </Flex>
        </Popup>
      </CustomModal>
    </>
  );
}

const CustomModal = styled.div<{visible: boolean}>`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 316px;
  height: ${({visible}) => (visible ? 'auto' : '0')};
  top: 84px;
  left: 24px;
  background-color: ${({theme}) => theme.bgPrimary};
  border-radius: 24px;
  padding: ${({visible}) => (visible ? '16px 0' : '0 12px')};
  border: ${({visible, theme}) =>
    visible
      ? `1px solid ${theme.borderPrimary}`
      : `0px solid ${theme.borderPrimary}`};
  z-index: 1000;
  gap: 8px;
  overflow: hidden;
  transition: height 300ms;
  ${({theme}) => theme.cardShadow}
`;

const ScrollView = styled.div`
  max-height: 284px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  gap: 8px;
  flex-direction: column;
  pointer-events: all;
`;
