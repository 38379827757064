import React, {useEffect, useState} from 'react';
import {Flex} from 'shared/ui/Flex';
import {TextInput} from 'shared/ui/TextInput';
import {TabsControl} from './TabsControl';

export const SalaryInputSwitch = ({
  value,
  onChange,
  required = false,
  label,
  switchLabels,
  activeIndex,
  onChangeTab,
  defaultWidth,
}: {
  value?: string;
  defaultWidth?: number;
  onChange: (text: string) => void;
  required?: boolean;
  label: string;
  activeIndex: number;
  switchLabels: {label: string; value: string}[];
  onChangeTab: (index: number) => void;
}) => {
  const [actualValue, setActualValue] = useState(value || '0');

  const formatVisibleSalary = (value: string) =>
    value.replace(/\D/g, '').replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ') +
    ' ₽';

  const formatRawValue = (value: string) =>
    value.replace(/\d $/, '').replace(/\D/g, '');

  useEffect(() => {
    setActualValue(formatRawValue(value || '0'));
  }, [value]);

  const handleInputChange = (text: string) => {
    const rawValue = Math.min(
      Number(formatRawValue(text)),
      999999999,
    ).toString();
    setActualValue(rawValue);
    onChange(rawValue);
  };

  return (
    <Flex alignItems="center" gap={12}>
      <TextInput
        value={formatVisibleSalary(actualValue)}
        onChange={handleInputChange}
        required={required}
        label={label}
        flex={1}
      />
      <TabsControl
        labels={switchLabels}
        defaultWidth={defaultWidth}
        onClick={onChangeTab}
        activeIndex={activeIndex}
      />
    </Flex>
  );
};
