import React, {useState} from 'react';
import {styled} from 'styled-components';
import {Popup} from 'shared/ui/Popup';
import {Flex} from 'shared/ui/Flex';
import {CirclePlus} from 'shared/icons/CirclePlus';
import {TextInput} from 'shared/ui/TextInput';
import {Search} from 'shared/icons/Search';
import {useColors} from 'shared/lib/hooks';
import {Divider} from 'shared/ui/Divider';
import {Text} from 'shared/ui/Text';
import {useLazyQuery} from '@apollo/client';
import {getEvent, getTime} from '..';
import {Skeleton} from 'shared/ui/Skeleton';
import {List} from 'shared/ui/List';
import {
  MessageSampleBookingStatus,
  MessageSampleEvent,
  MessageSampleMethod,
  MessageSampleTime,
} from 'shared/__generated__/graphql';
import {gql} from 'shared/__generated__';
import {EmptySendEmail} from 'shared/illustration/EmptySendEmail';

type IMessage = {
  name: string;
  method: MessageSampleMethod;
  event: MessageSampleEvent;
  time: MessageSampleTime;
  booking_status: MessageSampleBookingStatus;
  message: string;
};

const GET_READY_MESSAGE_SAMPLES = gql(`
  query GetReadyMessageSamples($first: Int!, $page: Int, $name: String) {
    getReadyMessageSamples(first: $first, page: $page, name: $name) {
      data {
        id
        name
        method
        event
        time
        booking_status
        message
      }
      paginatorInfo {
        hasMorePages
        currentPage
        total
        count
        lastPage
      }
    }
  }
`);

const ButtonMessage = styled.button`
  display: flex;
  padding: 0;
  border: none;
  background-color: transparent;
  gap: 8px;
  height: 48px;
  align-items: center;
  pointer-events: all;
  cursor: pointer;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: ${({theme}) => theme.mainPrimary};
  align-items: center;
`;
const Message = styled(Flex)`
  gap: 4px;
  flex-direction: column;
  width: 100%;
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
`;
const ListWrapper = styled.div`
  overflow-y: auto;
  max-height: 419px;
`;

export const GetReadyMessage = ({
  onSelect,
}: {
  onSelect: (message: IMessage) => void;
}) => {
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState('');
  const colors = useColors();

  const [getMessage, {data, loading, called}] = useLazyQuery(
    GET_READY_MESSAGE_SAMPLES,
    {
      variables: {
        first: 10,
        page: 1,
      },
    },
  );
  const messages = data?.getReadyMessageSamples?.data ?? [];
  return (
    <>
      <ButtonMessage
        onClick={() => {
          if (!called) getMessage();
          setVisible(true);
        }}>
        <CirclePlus size={20} /> Выбрать готовый шаблон
      </ButtonMessage>
      <Popup
        style={{
          width: 672,
          padding: 16,
          minHeight: 400,
          maxHeight: 548,
        }}
        visible={visible}
        onClose={() => setVisible(false)}>
        <Flex direction="column" gap={16} style={{width: '100%'}} flex={1}>
          <TextInput
            wrapperStyle={{width: '100%'}}
            variant="default"
            placeholder="Поиск готовых шаблонов рассылок"
            leftElement={<Search color={colors.textTertiary} />}
            value={search}
            onChange={setSearch}
          />
          <Divider />
          {loading ? (
            <Skeleton height={300}>
              {Array.from(Array(4).keys()).map((_, index) => (
                <React.Fragment key={'message_sk_' + index}>
                  <rect width={196} height={24} y={72 * index} rx={8} ry={8} />
                  <rect
                    width={235}
                    height={20}
                    y={28 + 72 * index}
                    rx={8}
                    ry={8}
                  />
                </React.Fragment>
              ))}
            </Skeleton>
          ) : (
            <ListWrapper>
              {messages.length === 0 ? (
                <Flex direction="column" gap={16} alignItems="center">
                  <EmptySendEmail size={300} />
                  <Text typography="title20">Не найдено</Text>
                  <Text typography="text18" color="textTertiary">
                    Попробуйте изменить запрос
                  </Text>
                </Flex>
              ) : (
                <List
                  gap={16}
                  data={messages}
                  renderItem={message => (
                    <Message
                      onClick={() => {
                        onSelect(message);
                        setVisible(false);
                      }}
                      key={'message_' + message.id}>
                      <Text align="left">{message.name}</Text>
                      <Text color="textTertiary" typography="subHead14Regular">
                        {getTime(message.time) + ' ' + getEvent(message.event)}
                      </Text>
                    </Message>
                  )}
                  placeholder={
                    <Text
                      align="center"
                      style={{padding: '0 24px'}}
                      color="textTertiary">
                      Не найдено шаблонов
                    </Text>
                  }
                />
              )}
            </ListWrapper>
          )}
        </Flex>
      </Popup>
    </>
  );
};
