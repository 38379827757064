import React, {useState} from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {device} from 'shared/device';
import {Flex} from 'shared/ui/Flex';
import {Content} from 'shared/ui/Content';
import {TextInput} from 'shared/ui/TextInput';
import {Select} from 'shared/ui/Select';
import {DragDrop} from 'shared/ui/DragDrop';
import {TextArea} from 'shared/ui/TextArea';
import {NavLink} from 'shared/ui/NavLink';
import {gql} from 'shared/__generated__';
import {useMutation, useQuery} from '@apollo/client';
import {useAppSelector} from 'shared/store';
import {CitiesSelect} from 'entities/city';
import {ensureError} from 'shared/lib/utils';
import {Button} from 'shared/ui/Button';
import {showAlert} from 'shared/ui/Alert';
import {useNavigate} from 'react-router-dom';
import {getAbbreviatedSchedule} from 'entities/schedule';
import {Image} from 'shared/ui/Image';
import {ImageType, Image as ImageGQL} from 'shared/__generated__/graphql';
import {ImageView} from 'shared/ui/ImageView';
import {useColors} from 'shared/lib/hooks';
import {CompanyEditSkeleton} from './ui/Skeleton';
import {AvatarCrop} from 'shared/ui/AvatarCrop';
import {TUTORIALCHECK} from 'entities/tutorial';
import {Seo} from 'shared/ui/Seo';
import {ME} from 'shared/ui/MenuItem';

const COMPANY_ABOUT = gql(`
query Company($companyId: String) {
  company(id: $companyId) {
    id
    images {
      id
      url
      url_256
    }
    type {
      id
      title
    }
    specialization {
      id
      title
    }
    address
    avatar {
      id
      url
    }
    description
    name
    city {
      id
      name
    }
    workingDays {
      id
      day
      start_time
      end_time
      break_start_time
      break_end_time
      dayoff
    }
  }

  companySpecialization {
      id
      title
      types {
        id
        title
      }
  }
}
`);

const COMPANY_ABOUT_UPDATE = gql(`
  mutation MutationCompanyAbout($input: UpdateCompany!) {
    updateCompany(input: $input) {
      id
    }
  }
`);

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 8px;
  padding-bottom: 24px;
  gap: 24px;
  @media (${device.mobile}) {
    gap: 8px;
    margin-bottom: 0;
    border-radius: 0;
  }
`;
const ImageArea = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 88px);
  gap: 8px;
`;

export const CompanyEdit = () => {
  const navigate = useNavigate();
  const username = useAppSelector(state => state.company.data?.username);
  const colors = useColors();
  const companyId = useAppSelector(state => state.company.data!.id);
  const {data, loading} = useQuery(COMPANY_ABOUT, {
    variables: {companyId},
    onCompleted(res) {
      if (res) {
        setAvatar(res.company?.avatar?.url ?? '');
        setName(res.company?.name ?? '');
        setSpecialization(res.company?.specialization.id ?? '');
        setType(res.company?.type.id ?? '');
        setCity(res.company?.city?.id ?? '');
        setAddress(res.company?.address ?? '');
        setDescription(res.company?.description ?? '');
        setPhotos(res.company?.images ?? []);
      }
    },
  });
  const [avatar, setAvatar] = useState<string>();
  const [name, setName] = useState('');
  const [specialization, setSpecialization] = useState('');
  const [type, setType] = useState<string | undefined>('');
  const [city, setCity] = useState('');
  const week =
    data?.company?.workingDays
      .map(item => ({
        day: item?.day,
        start_time: item?.start_time,
        end_time: item?.end_time,
        dayoff: item?.dayoff,
      }))
      .sort((a, b) => {
        const daysOrder = [
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday',
          'sunday',
        ];
        return daysOrder.indexOf(a?.day) - daysOrder.indexOf(b?.day);
      }) ?? [];

  const [address, setAddress] = useState('');
  const [description, setDescription] = useState('');
  const [photos, setPhotos] = useState<
    Pick<ImageGQL, 'id' | 'url' | 'url_256'>[]
  >([]);
  const [imageVisible, setImageVisible] = useState(false);
  const [indexPhoto, setIndexPhoto] = useState(0);
  const [errorName, setErrorName] = useState<string | null>(null);
  const [errorSphere, setErrorSphere] = useState<string | null>(null);
  const [errorType, setErrorType] = useState<string | null>(null);
  const [errorCity, setErrorCity] = useState<string | null>(null);
  const [errorAddres, setErrorAddres] = useState<string | null>(null);

  const sphere = data?.companySpecialization;
  const typesSalon = data?.companySpecialization.find(
    item => item.id === specialization,
  )?.types;

  const [updateCompany, {loading: updateLoading}] = useMutation(
    COMPANY_ABOUT_UPDATE,
    {
      refetchQueries: [
        {
          query: COMPANY_ABOUT,
          variables: {
            companyId,
          },
        },
        'CompanyInfo',
        {
          query: ME,
        },
        {
          query: TUTORIALCHECK,
          variables: {
            companyId,
          },
        },
      ],
    },
  );
  const update = async () => {
    try {
      await updateCompany({
        variables: {
          input: {
            id: companyId,
            images:
              avatar !== undefined
                ? {
                    create: [
                      {
                        url: avatar,
                        url_64: avatar,
                        url_128: avatar,
                        url_256: avatar,
                        url_1536: avatar,
                        type: ImageType.Avatar,
                      },
                    ],
                  }
                : data?.company?.avatar?.id
                ? {delete: [data?.company?.avatar?.id]}
                : undefined,
            name: name,
            specialization_id: specialization,
            type_id: type,
            city_id: city,
            address: address,
            description: description,
          },
        },
      });
      showAlert('success', 'Coxранено');
    } catch (e) {
      const error = ensureError(e);
      showAlert('error', error.message);
    }
  };

  return (
    <Wrapper>
      <Seo title="Об организации" />
      <ImageView
        visible={imageVisible}
        onClose={() => setImageVisible(false)}
        images={photos.map(photo => photo.url)}
        index={indexPhoto}
      />
      <Content>
        <Text typography="title18">Основная информация</Text>
        {loading ? (
          <CompanyEditSkeleton type="caseInfo" />
        ) : (
          <>
            <AvatarCrop
              variant="company"
              onChange={value => {
                setAvatar(value?.url ?? undefined);
              }}
              onDelete={() => {
                setAvatar(undefined);
              }}
              url={avatar}
            />
            <TextInput
              required
              label="Название организации"
              value={name}
              error={errorName}
              maxLength={30}
              onChange={text => {
                setName(text);
                setErrorName(null);
              }}
            />
            <Select
              required
              label="Сфера организации"
              value={specialization}
              error={errorSphere}
              data={sphere?.map(item => ({
                value: item.id,
                label: item.title,
              }))}
              onChange={selected => {
                setSpecialization(selected);
                setErrorSphere(null);
                setType(undefined);
              }}
            />
            <Select
              required
              error={errorType}
              label="Тип организации"
              value={type}
              data={typesSalon?.map(item => ({
                value: item.id,
                label: item.title,
              }))}
              onChange={selected => {
                setType(selected);
                setErrorType(null);
              }}
            />
          </>
        )}
      </Content>
      <Content>
        <Text typography="title18">Фотографии салона</Text>
        {loading ? (
          <CompanyEditSkeleton type="caseImage" />
        ) : (
          <>
            <DragDrop
              onChange={value => {
                updateCompany({
                  variables: {
                    input: {
                      id: companyId,
                      images: {
                        create: value?.map(item => ({
                          type: ImageType.Image,
                          ...item,
                        })),
                      },
                    },
                  },
                });
              }}
            />
            <ImageArea>
              {photos.map((photo, index) => (
                <Image
                  key={'booking_photo_' + photo.id}
                  src={photo.url}
                  onClick={() => {
                    setIndexPhoto(index);
                    setImageVisible(true);
                  }}
                  onDelete={() => {
                    updateCompany({
                      variables: {
                        input: {
                          id: companyId,
                          images: {
                            delete: [photo.id],
                          },
                        },
                      },
                    });
                  }}
                />
              ))}
            </ImageArea>
            <Flex direction="column" gap={8}>
              <Text typography="subHead14Regular" color="textTertiary">
                Доступные форматы: jpeg, jpg, png
              </Text>
              <Text typography="subHead14Regular" color="textTertiary">
                Размер файла: до 12 Мб
              </Text>
            </Flex>
          </>
        )}
      </Content>
      <Content>
        <Text typography="title18">Адрес и описание</Text>
        {loading ? (
          <CompanyEditSkeleton type="caseAddress" />
        ) : (
          <>
            <CitiesSelect
              required
              error={errorCity}
              value={city}
              onChange={selected => {
                setCity(selected);
                setErrorCity(null);
              }}
            />
            <TextInput
              required
              error={errorAddres}
              label="Адрес"
              value={address}
              onChange={text => {
                setAddress(text);
                setErrorAddres(null);
              }}
            />
            <TextArea
              size="large"
              maxLength={1000}
              label="Об организации"
              placeholder="Расскажите, почему клиенты должны выбрать именно вас. Сколько лет вы на рынке, какие даёте гарантии, в чём ваши преимущества перед конкурентами."
              value={description}
              onChange={text => {
                setDescription(text);
              }}
            />
          </>
        )}
      </Content>
      <Content>
        <Text typography="title18">Режим работы</Text>
        <NavLink
          label={getAbbreviatedSchedule(week)}
          styleLabel={{color: colors.textSecondary}}
          onClick={() => {
            navigate(`/${username}/company-schedule`);
          }}
        />
      </Content>
      <Button
        size="large"
        loading={updateLoading}
        onClick={() => {
          let isValid = true;
          if (!name) {
            setErrorName('Обязательное поле');
            isValid = false;
          }
          if (!specialization) {
            setErrorSphere('Выберите сферу');
            isValid = false;
          }
          if (!type) {
            setErrorType('Выберите тип');
            isValid = false;
          }
          if (!city) {
            setErrorCity('Выберите город');
            isValid = false;
          }
          if (!address) {
            setErrorAddres('Обязательное поле');
            isValid = false;
          }
          if (isValid) {
            update();
          }
        }}>
        Сохранить
      </Button>
    </Wrapper>
  );
};
