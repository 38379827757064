import dayjs from 'dayjs';
import {
  Company,
  CompanyBookingStatus,
  CustomerGender,
  CustomerStatus,
  DiscountType,
  Employee,
  ImageType,
  ImageUpload,
  PaymentMethodEnum,
  PriceList,
  Service,
  StatisticsDateTime,
  StatisticsDates,
  SubscriptionsType,
} from 'shared/__generated__/graphql';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

export type TariffPlan = Omit<PriceList, 'updated_at'>;
dayjs.extend(isSameOrBefore);
type ServiceType = Pick<Service, 'min_price' | 'max_price' | 'price' | 'type'>;
export const mobileMediaQuery = '@media (max-width: 768px)';
export const desktopMediaQuery = '@media (min-width: 768px)';

export const PHONE_MASK = '+7 (999) 999-99-99';

export const Periods: Record<
  Exclude<StatisticsDates, StatisticsDates.Period>,
  string
> = {
  today: 'За сегодня',
  yesterday: 'За вчера',
  last7days: 'За последние 7 дней',
  last30days: 'За последние 30 дней',
  last3months: 'За последние 90 дней',
  alltime: 'За все время',
};

export const Sales: Record<StatisticsDateTime, string> = {
  day: 'Продажи по дням',
  month: 'По месяцам',
  quarter: 'По кварталам',
  year: 'По годам',
};

export const Gender: Record<CustomerGender, string> = {
  M: 'Мужской',
  F: 'Женский',
};

export function testNever(a: never) {
  console.error(`never expected, got ${a}`);
}

export const weekdays = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

export const EmployeeRole: Record<string, string> = {
  admin: 'Администратор',
  owner: 'Владелец',
  participant: 'Сотрудник',
};

export const StatusText: Record<CompanyBookingStatus, string> = {
  completed: 'Пришёл',
  cancelled: 'Не пришёл',
  pending: 'Ожидание',
  confirmed: 'Подтвердил',
};

export const PaymentMethodsText: Record<PaymentMethodEnum, string> = {
  cash: 'Наличные',
  card: 'Безналичные',
  bonus_card: 'Бонусная карта',
  certificate: 'Сертификат',
};

export const Status: Record<CustomerStatus | string, string> = {
  vip: 'VIP',
  default: 'Обычный',
  blocked: 'Заблокирован',
};

export const isEmailValid = (email: string) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
};

export function ensureError(value: unknown): Error {
  if (value instanceof Error) return value;

  let stringified = '[Unable to stringify the thrown value]';
  try {
    stringified = JSON.stringify(value);
  } catch {
    console.error('unable to stringify');
  }

  const error = new Error(
    `This value was thrown as is, not through an Error: ${stringified}`,
  );
  return error;
}

export const getName = (
  name?: string | null,
  surname?: string | null,
  type: 'employee' | 'customer' = 'customer',
): string => {
  if (name && surname) {
    return name + ' ' + surname;
  }
  if (name) {
    return name;
  }
  if (surname) {
    return surname;
  }
  return type === 'customer' ? 'Новый клиент' : 'Новый сотрудник';
};

export const getShortName = (
  name?: string | null,
  surname?: string | null,
  type: 'employee' | 'customer' = 'customer',
): string => {
  if (name && surname) {
    return name + ' ' + surname[0] + '.';
  }
  if (name) {
    return name;
  }
  if (surname) {
    return surname;
  }
  return type === 'customer' ? 'Новый клиент' : 'Новый сотрудник';
};

export const capitalize = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const getRating = (
  object:
    | Pick<
        Employee,
        | 'rating_1_count'
        | 'rating_2_count'
        | 'rating_3_count'
        | 'rating_4_count'
        | 'rating_5_count'
      >
    | Pick<
        Company,
        | 'rating_1_count'
        | 'rating_2_count'
        | 'rating_3_count'
        | 'rating_4_count'
        | 'rating_5_count'
      >
    | undefined,
) => {
  if (!object) {
    return 0;
  }
  const ratings: number[] = [
    object.rating_1_count,
    object.rating_2_count,
    object.rating_3_count,
    object.rating_4_count,
    object.rating_5_count,
  ];
  const answer = (
    ratings.reduce(
      (accumulator, currentValue, currentIndex) =>
        accumulator + currentValue * (currentIndex + 1),
      0,
    ) /
    ratings.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  ).toFixed(1);
  return +answer ? +answer : 0;
};

export const getTimeArray = (
  interval: number,
  start_time: number,
  end_time: number,
) => {
  const data: string[] = [];

  for (let i = start_time; i <= end_time; i += interval) {
    const hour = Math.trunc(i / 60);
    const minute = i % 60;
    const hourStr = hour < 10 ? `0${hour}` : hour.toString();
    const minuteStr = minute < 10 ? `0${minute}` : minute.toString();
    data.push(hourStr + ':' + minuteStr);
  }

  return data;
};

export const getPhoneMask = (value?: string | null, customPattern?: string) => {
  if (!value || value.length === 0) {
    return 'Номер не указан';
  }
  const pattern = customPattern ?? '+7 (###) ###-##-##';
  let i = 0;
  let val = value.replace(/\D/g, '').replace(/^8/, '7');
  if (val.length === 10) {
    val = '7' + val;
  }
  return pattern.replace(/./g, char => {
    if (/[#\d]/.test(char) && i < val.length) {
      return val.charAt(i++);
    }
    return i >= value.length ? '' : char;
  });
};

export const getMinutStartBooking = (value: number) => {
  const arr = [];
  const v = 5;
  for (let i = 0; i <= value; i++) {
    const hour = Math.floor((i * v) / 60);
    const min = (i * v) % 60;
    const first = hour > 0 ? hour + ' ч ' : '';
    const second = min > 0 ? min + ' мин' : '';
    const name = i === 0 ? '0 мин' : `${first}${second}`;
    arr.push({
      id: i,
      name: name,
      value: i === 0 ? 0 : i * v,
    });
  }
  return arr;
};

export const getDurationHourMinutes = (duration: number) => {
  if (duration >= 60) {
    return (
      Math.trunc(duration / 60) +
      ' ч. ' +
      (duration % 60 === 0 ? '' : (duration % 60) + ' мин.')
    );
  } else {
    return duration + ' мин.';
  }
};
const dayMinutes = 1440;
export const getDataWorkTime = (interval?: number) => {
  const currentInterval = interval ?? 30;
  const iterations = Math.ceil(dayMinutes / currentInterval);
  const day = dayjs().startOf('day');
  const times = [];
  let iterator = 0;
  while (iterator < iterations) {
    times.push(day.add(iterator * currentInterval, 'm').format('HH:mm'));
    iterator++;
  }
  return times.map(time => ({label: time, value: time}));
};

export const getDiscountCalc = (
  price: number,
  discount_type?: DiscountType | undefined,
  discount_value?: number,
) => {
  switch (discount_type) {
    case 'fixed':
      return Math.max(0, price - (discount_value ?? 0));
    case 'percentage':
      return price - (price / 100) * (discount_value ?? 1);
    default:
      return price;
  }
};

export const getDates = ({start, end}: {start: string; end: string}) => {
  const dates = [];
  let currentDate = dayjs(start);

  while (currentDate.isSameOrBefore(end)) {
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.add(1, 'day');
  }

  return dates;
};

export const getStartEndWeekDaysMonth = (date: dayjs.Dayjs) => {
  const currentDayStart = date.startOf('month').clone().startOf('week');
  const currentDayEnd = date.endOf('month').clone().endOf('week');
  const dates = {
    start: currentDayStart.format('YYYY-MM-DD'),
    end: currentDayEnd.format('YYYY-MM-DD'),
  };
  return dates;
};

export const getServicePrice = (service: ServiceType) => {
  switch (service.type) {
    case 'fixed':
      return Number(service.price).toFixed(0) + ' ₽';
    case 'not_fixed':
      return Number(service.min_price).toFixed(0) + ' ₽';
    case 'range':
      return (
        Number(service.min_price).toFixed(0) +
        ' - ' +
        Number(service.max_price).toFixed(0) +
        ' ₽'
      );
    default:
      return 'Бесплатно';
  }
};

export const modifyUrl = (value: string) => {
  if (value.startsWith('https://www.')) {
    return value.substring(12);
  } else if (value.startsWith('https://')) {
    return value.substring(8);
  }
  return value;
};

export const periods = [
  {title: 'Ежемесячно', type: SubscriptionsType.Month, value: 0},
  {
    title: 'Каждые 3 месяца',
    type: SubscriptionsType.Threemonths,
    value: 5,
  },
  {
    title: 'Каждые 6 месяцев',
    type: SubscriptionsType.Sixmonths,
    value: 10,
  },
  {title: 'Ежегодно', type: SubscriptionsType.Year, value: 20},
];

export const getMonths = (value: SubscriptionsType) => {
  switch (value) {
    case 'sixmonths':
      return 6;
    case 'threemonths':
      return 3;
    case 'year':
      return 12;
    default:
      return 1;
  }
};
export const getTariff = (
  value: TariffPlan,
  separator: 'slash' | 'dash' | undefined = 'slash',
) => {
  const selectSeparator = separator === 'slash' ? ' / ' : ' — ';
  if (value.type === 'forever') return 'Бесплатный';
  switch (value.slots) {
    case 1:
      return (
        value.slots +
        ' сотрудник' +
        selectSeparator +
        value.price / getMonths(value.type) +
        ' ₽ мес.'
      );
    default:
      return (
        value.slots +
        ' сотрудников' +
        selectSeparator +
        value.price / getMonths(value.type) +
        ' ₽ мес.'
      );
  }
};

export const getPriceFormat = (value: string | number | undefined | null) => {
  const checkValue = value ? +value : 0;
  return new Intl.NumberFormat('ru-RU').format(checkValue) + '\u00A0₽';
};

export const getNumberFormat = (
  value: string | number | undefined | null,
  extra: string | undefined = '',
) => {
  const checkValue = value ? +value : 0;
  return new Intl.NumberFormat('ru-RU').format(checkValue) + extra;
};

export const getTypedDate = (date: string) => {
  const difference = dayjs(dayjs().format('YYYY-MM-DD 00:00:00')).diff(
    date,
    'hour',
  );
  if (difference > 24 && difference <= 48) {
    return dayjs(date).format('DD MMMM, Позавчера');
  }
  if (difference > 0 && difference <= 24) {
    return dayjs(date).format('DD MMMM, Вчера');
  }
  if (difference === 0) {
    return dayjs(date).format('DD MMMM, Сегодня');
  }
  if (difference < 0 && difference >= -24) {
    return dayjs(date).format('DD MMMM, Завтра');
  }
  if (difference < -24 && difference >= -48) {
    return dayjs(date).format('DD MMMM, Послезавтра');
  }
  return dayjs(date).format('DD MMMM, dddd');
};

export type PresetType = 'days' | 'period' | 'date' | 'months';

export type Period = {
  label?: string;
  value: string;
  type: PresetType;
  infinity?: 'prev' | 'next' | 'both';
};

export enum TYPE {
  Preset = 'preset',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Period = 'period',
}
export const types = [
  {label: 'Пресеты', type: TYPE.Preset},
  {label: 'День', type: TYPE.Day},
  {label: 'Неделя', type: TYPE.Week},
  {label: 'Месяц', type: TYPE.Month},
  {label: 'Период', type: TYPE.Period},
];

export const salaryPresets: {label: string; value: string; type: PresetType}[] =
  [
    {
      label: 'За сегодняшний день',
      value: dayjs().format('YYYY-MM-DD'),
      type: 'date',
    },
    {
      label: 'За вчерашний день',
      value: dayjs().add(-1, 'day').format('YYYY-MM-DD'),
      type: 'date',
    },
    {
      label: 'За предыдущие 3 дня',
      value: '3',
      type: 'days',
    },
    {
      label: 'За предыдущие 7 дня',
      value: '7',
      type: 'days',
    },
    {
      label: 'За предыдущие 14 дня',
      value: '14',
      type: 'days',
    },
    {
      label: 'За предыдущие 30 дня',
      value: '30',
      type: 'days',
    },
    {
      label: 'За предыдущие 60 дня',
      value: '60',
      type: 'days',
    },
    {
      label: 'За предыдущий квартал',
      value: 'quarter',
      type: 'months',
    },
    {
      label: 'За предыдущий год',
      value: 'year',
      type: 'months',
    },
  ];
export const schedulePresets: {
  label: string;
  value: string;
  type: PresetType;
}[] = [
  {
    label: '7 дней',
    value: '7',
    type: 'days',
  },
  {
    label: '14 дней',
    value: '14',
    type: 'days',
  },
  {
    label: '30 дней',
    value: '30',
    type: 'days',
  },
  {
    label: '60 дней',
    value: '60',
    type: 'days',
  },
  {
    label: '90 дней',
    value: '90',
    type: 'days',
  },
];

export const processedAvatar = (
  avatarUpload: Omit<ImageUpload, 'type'> | undefined,
  avatarDownloadUrl?: string,
) => {
  if (avatarUpload?.url === avatarDownloadUrl) return undefined;
  if (avatarUpload) return {type: ImageType.Avatar, ...avatarUpload};
  if (avatarDownloadUrl && !avatarUpload)
    return {type: ImageType.Avatar, url: null};
  return null;
};
