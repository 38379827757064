import React, {useState} from 'react';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {gql} from 'shared/__generated__';
import {useAppSelector} from 'shared/store';
import {Layout} from 'shared/ui/Layout';
import {PageHeader} from 'shared/ui/PageHeader';
import {TextInput} from 'shared/ui/TextInput';
import {Flex} from 'shared/ui/Flex';
import {ApolloError, useMutation} from '@apollo/client';
import {CircleClose} from 'shared/icons/CircleClose';
import {useColors} from 'shared/lib/hooks';
import {Divider} from 'shared/ui/Divider';
import {CirclePlus} from 'shared/icons/CirclePlus';
import {Text} from 'shared/ui/Text';
import {MultiSelectChips} from 'shared/ui/MultiSelectChips';
import {SelectEmployees, SelectServices} from 'entities/loyalty';
import {Button} from 'shared/ui/Button';
import {
  CreateSalaryRule,
  CreateSalaryRuleService,
} from 'shared/__generated__/graphql';
import {showAlert} from 'shared/ui/Alert';
import {
  GET_RULES_SALARIES,
  SalaryData,
  SalaryInputSwitch,
  SalarySelectedServices,
  TextInputSwitch,
  TitleContent,
  salaryPeriod,
  salaryTime,
  salaryType,
} from 'entities/salary';
import {IconButton} from 'shared/ui/IconButton';

const CREATE_SALARY_RULE = gql(`
  mutation CreateSalaryRule($input: CreateSalaryRule!, $companyId: String!) {
    createSalaryRule(input: $input, company_id: $companyId) {
      id
    }
  }
`);
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  gap: 24px;
  padding-bottom: 24px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const AddSpecify = styled.button`
  display: flex;
  gap: 8px;
  height: 48px;
  border: none;
  background-color: transparent;
  align-items: center;
  cursor: pointer;
  pointer-events: all;
`;

const defaultService = {
  id: 1,
  services: [],
  value: '',
  typeIndex: '0',
};

export const AddSalaryRule = () => {
  const navigate = useNavigate();
  const colors = useColors();
  const username = useAppSelector(state => state.company.data?.username);
  const companyId = useAppSelector(state => state.company.data!.id);
  const [maxLength, setMaxLength] = useState<number>();
  const [createSalaryRule, {loading: createLoading}] = useMutation(
    CREATE_SALARY_RULE,
    {
      refetchQueries: [
        {query: GET_RULES_SALARIES, variables: {companyId, first: 10, page: 1}},
      ],
    },
  );

  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const [serviceType, setServiceType] = useState(0);

  const [errorText, setErrorText] = useState<string | null>(null);

  const [specifyServices, setSpecifyServices] = useState<
    SalarySelectedServices[]
  >([defaultService]);

  const [salary, setSalary] = useState('');
  const [periodSalary, setPeriodSalary] = useState(0);
  const [guaranteedMinimum, setGuaranteedMinimum] = useState('');
  const [guaranteedMinimumType, setGuaranteedMinimumType] = useState(0);
  const [selectedEmployees, setSelectedEmployees] = useState<
    {label: string; value: string}[]
  >([]);
  const excludes = specifyServices.reduce(
    (accumulator, currentValue) => [...accumulator, ...currentValue.services],
    [] as SalaryData[],
  );

  const setChanged = (
    key: 'typeIndex' | 'value' | 'services',
    index: number,
    newValue: string | SalaryData[],
  ) => {
    if (typeof newValue === 'string' && key !== 'services') {
      const copy = [...specifyServices];
      copy[index][key] = newValue;
      setSpecifyServices(copy);
      return;
    }
    if (typeof newValue !== 'object') return;
    const copy = [...specifyServices];
    copy[index].services = newValue;
    setSpecifyServices(copy);
  };

  return (
    <Layout>
      <Wrapper>
        <PageHeader back variant="layout" title="Создание правила расчета" />
        <TitleContent title="Основное">
          <TextInput
            value={name}
            onChange={value => {
              setName(value);
              setErrorText(null);
            }}
            required
            label="Название правила расчета"
            error={errorText}
          />
          <TextInputSwitch
            switchLabels={salaryType}
            onChangeTab={index => {
              if (index === 0 && +value > 100) {
                setValue('100');
              }
              setServiceType(index);
            }}
            activeIndex={serviceType}
            label="С услуг"
            value={value}
            onChange={setValue}
          />
        </TitleContent>
        <TitleContent title="Уточнить по категориям и услугам">
          {specifyServices.map((service, index) => (
            <Flex gap={8} alignItems="center" key={index + '_specifyIndex'}>
              <Flex style={{width: '100%'}} gap={16} direction="column">
                {index !== 0 && <Divider />}
                <MultiSelectChips
                  label="Услуги"
                  values={service.services}
                  modal={(visible, setVisible) => (
                    <SelectServices
                      label="Услуги"
                      visible={visible}
                      excludes={excludes}
                      values={service.services}
                      onChange={(values, maxLength) => {
                        if (maxLength) setMaxLength(maxLength);
                        setChanged('services', index, values);
                        setVisible(false);
                      }}
                      onClose={() => setVisible(false)}
                    />
                  )}
                  onDelete={id => {
                    const copy = [...specifyServices];
                    copy[index].services = specifyServices[
                      index
                    ].services.filter(service => service.value !== id);
                    setSpecifyServices(copy);
                  }}
                />
                <TextInputSwitch
                  switchLabels={salaryType}
                  onChangeTab={typeIndex =>
                    setChanged('typeIndex', index, typeIndex.toString())
                  }
                  activeIndex={+service.typeIndex}
                  label="С услуги"
                  value={service.value}
                  onChange={text => setChanged('value', index, text)}
                />
              </Flex>
              <IconButton
                variant="unstyled"
                onClick={() => {
                  setSpecifyServices(prev =>
                    prev.filter((_, deleteIndex) => deleteIndex !== index),
                  );
                }}>
                <CircleClose color={colors.dangerPrimary} />
              </IconButton>
            </Flex>
          ))}
          {(!maxLength || (maxLength && excludes.length !== maxLength)) &&
            specifyServices.length < 5 && (
              <AddSpecify
                onClick={() => {
                  const newId =
                    specifyServices.length > 0
                      ? specifyServices[specifyServices.length - 1].id + 1
                      : 1;
                  setSpecifyServices([
                    ...specifyServices,
                    {id: newId, services: [], value: '', typeIndex: '0'},
                  ]);
                }}>
                <CirclePlus color={colors.mainPrimary} />
                <Text color="mainPrimary">Добавить поле</Text>
              </AddSpecify>
            )}
        </TitleContent>
        <TitleContent title="Минимальная прибыль">
          <SalaryInputSwitch
            defaultWidth={70}
            switchLabels={salaryTime}
            onChangeTab={setPeriodSalary}
            activeIndex={periodSalary}
            label="Оклад"
            value={salary}
            onChange={setSalary}
          />
          <SalaryInputSwitch
            switchLabels={salaryPeriod}
            defaultWidth={80}
            onChangeTab={setGuaranteedMinimumType}
            activeIndex={guaranteedMinimumType}
            label="Гарантированный минимум"
            value={guaranteedMinimum}
            onChange={setGuaranteedMinimum}
          />
        </TitleContent>
        <TitleContent title="Сотрудники">
          <MultiSelectChips
            label="Выберите сотрудников"
            values={selectedEmployees}
            onDelete={id =>
              setSelectedEmployees(
                selectedEmployees.filter(selectItem => selectItem.value !== id),
              )
            }
            modal={(visible, setVisible) => (
              <SelectEmployees
                label="Выберите сотрудников"
                visible={visible}
                values={selectedEmployees}
                onChange={values => {
                  setSelectedEmployees(values);
                  setVisible(false);
                }}
                onClose={() => setVisible(false)}
              />
            )}
          />
        </TitleContent>
        <Button
          size="large"
          loading={createLoading}
          disabled={createLoading}
          onClick={() => {
            if (name !== '') {
              const custom_services: CreateSalaryRuleService[] = [];
              specifyServices.forEach(item => {
                item.services.forEach(service => {
                  const custom_service: CreateSalaryRuleService = {
                    service_id: service.value,
                    service_salary_type: salaryType[+item.typeIndex].value,
                    service_salary_value: +item.value,
                  };
                  custom_services.push(custom_service);
                });
              });
              const input: CreateSalaryRule = {
                custom_services,
                employees: selectedEmployees.map(employee => employee.value),
                guaranteed_minimum_type:
                  salaryPeriod[guaranteedMinimumType].value,
                guaranteed_minimum_value: +guaranteedMinimum,
                name,
                salary_type: salaryTime[periodSalary].value,
                salary_value: +salary,
                service_salary_type: salaryType[serviceType].value,
                service_salary_value: +value,
              };
              createSalaryRule({
                variables: {
                  companyId,
                  input,
                },
              })
                .then(() => {
                  navigate(`/${username}/salary`, {
                    state: 'rules',
                    replace: true,
                  });
                  showAlert('success', 'Успешно создано новое правило!');
                })
                .catch((e: ApolloError) => showAlert('error', e.message));
            } else {
              setErrorText('Обязательное поле');
            }
          }}>
          Сохранить
        </Button>
      </Wrapper>
    </Layout>
  );
};
