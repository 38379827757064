import React, {useRef, useState} from 'react';
import {Text} from 'shared/ui/Text';
import styled from 'styled-components';
import {Content} from 'shared/ui/Content';
import {useNavigate, useParams} from 'react-router-dom';
import {useAppSelector} from 'shared/store';
import {Layout} from 'shared/ui/Layout';
import {PageHeader} from 'shared/ui/PageHeader';
import {Flex} from 'shared/ui/Flex';
import {Switch} from 'shared/ui/Switch';
import {TextInput} from 'shared/ui/TextInput';
import {TextArea} from 'shared/ui/TextArea';
import {
  GET_MESSAGES,
  GetReadyMessage,
  Preset,
  messageSampleBookingsStatus,
  messageSampleEvents,
  messageSampleMethods,
  messageSampleTimes,
  presets,
} from 'entities/messageTemplates';
import {Select} from 'shared/ui/Select';
import {Button} from 'shared/ui/Button';
import {ApolloError, useMutation, useQuery} from '@apollo/client';
import {gql} from 'shared/__generated__';
import {showAlert} from 'shared/ui/Alert';
import {UpdateMessageSample} from 'shared/__generated__/graphql';
import {Trash} from 'shared/icons/Trash';
import {useColors} from 'shared/lib/hooks';
import {Error} from 'shared/ui/Error';
import {Skeleton} from 'shared/ui/Skeleton';
import {
  RichTextarea,
  RichTextareaHandle,
  createRegexRenderer,
} from 'rich-textarea';

const GET_MESSAGE_SAMPLE = gql(`
  query GetMessageSample($getMessageSampleId: String!) {
    getMessageSample(id: $getMessageSampleId) {
      event
      is_active
      id
      method
      message
      name
      time
      booking_status
    }
  }
`);

const UPDATE_MESSAGE_SAMPLE = gql(`
  mutation UpdateMessageSample($input: UpdateMessageSample!) {
    updateMessageSample(input: $input) {
      id
    }
}`);

const DELETE_MESSAGE_SAMPLE = gql(`
  mutation DeleteMessageSample($deleteMessageSampleId: String!) {
    deleteMessageSample(id: $deleteMessageSampleId) {
      id
    }
}`);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4;
  grid-column-end: 10;
  gap: 24px;
  padding: 0 0 16px 0;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const RichView = styled.div`
  color: ${({theme}) => theme.textPrimary};
  position: relative;
`;

const valuePresets = presets.map(item => item.value);

const replaceValueToExample = (value: string) => {
  let copyText = value.replace(/\s+/g, ' ').replaceAll(/[()]/g, '');
  valuePresets.forEach(
    (item, index) =>
      (copyText = copyText.replaceAll(item, presets[index].example)),
  );
  return copyText;
};

const formatted = (value: string) => {
  return value.replace(/\s+/g, ' ').replaceAll(/[()]/g, '');
};

export const EditMessageTemplates = () => {
  const navigate = useNavigate();
  const colors = useColors();
  const {id} = useParams();
  const companyId = useAppSelector(state => state.company.data!.id);
  const [enable, setEnable] = useState(true);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState<string | null>(null);
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState<string>();
  const [method, setMethod] = useState(messageSampleMethods[0].value);
  const [event, setEvent] = useState(messageSampleEvents[0].value);
  const [time, setTime] = useState(messageSampleTimes[0].value);
  const [bookingStatus, setBookingStatus] = useState(
    messageSampleBookingsStatus[0].value,
  );
  const {error, loading} = useQuery(GET_MESSAGE_SAMPLE, {
    variables: {getMessageSampleId: id!},
    onCompleted: resData => {
      const res = resData.getMessageSample;
      setMethod(res.method);
      setEvent(res.event);
      setTime(res.time);
      setBookingStatus(res.booking_status);
      setMessage(res.message);
      setName(res.name);
      setEnable(res.is_active);
    },
  });
  const [updateMessage, {loading: updateLoading}] = useMutation(
    UPDATE_MESSAGE_SAMPLE,
    {
      refetchQueries: [
        {query: GET_MESSAGES, variables: {companyId, first: 20, page: 1}},
      ],
    },
  );
  const [deleteMessage] = useMutation(DELETE_MESSAGE_SAMPLE, {
    refetchQueries: [
      {query: GET_MESSAGES, variables: {companyId, first: 20, page: 1}},
    ],
  });

  const textAreaRef = useRef<RichTextareaHandle>(null);
  const [isFocused, setIsFocused] = useState(false);

  const styles = {
    color: colors.mainPrimary,
    backgroundColor: colors.mainLight50,
    borderRadius: 8,
  };

  const renderer = createRegexRenderer([
    [/{customer_name}/g, styles],
    [/{customer_surname}/g, styles],
    [/{company_name}/g, styles],
    [/{services_name}/g, styles],
    [/{manager_name}/g, styles],
    [/{manager_first_name}/g, styles],
    [/{manager_last_name}/g, styles],
    [/{manager_position}/g, styles],
    [/{date}/g, styles],
    [/{time}/g, styles],
    [/{full_duration}/g, styles],
    [/{url}/g, styles],
    [/{booking_amount_today}/g, styles],
    [/{booking_amount_tomorrow}/g, styles],
  ]);

  const insertAtCursor = (textToInsert: string) => {
    const input = textAreaRef.current;
    if (!input) return;

    const {selectionStart, selectionEnd} = input;
    const currentText = message;

    const newText =
      currentText.substring(0, selectionStart) +
      textToInsert +
      currentText.substring(selectionEnd);

    setMessage(newText);

    setTimeout(() => {
      if (input) {
        input.focus();
        input.selectionStart = input.selectionEnd =
          selectionStart + textToInsert.length;
      }
    }, 0);
  };

  if (error) {
    <Error message={error.message} />;
  }

  return (
    <Layout>
      <Wrapper>
        <PageHeader
          variant="layout"
          back
          title="Редактирование шаблона рассылок"
        />
        <Content gap="24px">
          <Text typography="title18">Основная информация</Text>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            onClick={() => setEnable(!enable)}
            style={{height: 48, pointerEvents: 'all', cursor: 'pointer'}}>
            <Text>Включить шаблон</Text>
            <Switch value={enable} />
          </Flex>
          {loading ? (
            <Skeleton height={64} width={'100%'}>
              <rect width={'100%'} x={0} y={0} height={64} rx={18} ry={18} />
            </Skeleton>
          ) : (
            <TextInput
              label="Название шаблона сообщений"
              required
              value={name}
              error={nameError}
              onChange={text => {
                nameError && setNameError(null);
                setName(text);
              }}
            />
          )}
          <GetReadyMessage
            onSelect={item => {
              setName(item.name);
              setMessage(item.message);
              setMethod(item.method);
              setEvent(item.event);
              setTime(item.time);
              setBookingStatus(item.booking_status);
            }}
          />
          <Flex style={{position: 'relative'}} gap={8} direction="column">
            <Text color="textTertiary" typography="subHead14Regular">
              Шаблон сообщения
            </Text>

            {loading ? (
              <Skeleton height={160} width={'100%'}>
                <rect width={'100%'} x={0} y={0} height={160} rx={18} ry={18} />
              </Skeleton>
            ) : (
              <RichView>
                <RichTextarea
                  ref={textAreaRef}
                  value={message}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  maxLength={5000}
                  onChange={e => {
                    const text = e.target.value;
                    messageError && setMessageError(undefined);
                    setMessage(text);
                  }}
                  style={{
                    color: colors.textPrimary,
                    padding: 16,
                    backgroundColor: 'transparent',
                    borderRadius: 18,
                    outline: 'none',
                    border: `solid 1px ${
                      messageError
                        ? colors.dangerPrimary
                        : isFocused
                        ? colors.mainPrimary
                        : colors.borderPrimary
                    }`,
                    gap: 14,
                    width: '100%',
                    minHeight: 160,
                    fontSize: '16px',
                    fontFamily: 'Inter',
                    fontWeight: 'normal',
                    maxWidth: '100%',
                    minWidth: '100%',
                    caretColor: colors.textPrimary,
                  }}
                  placeholder="Введите текст сообщения">
                  {renderer}
                </RichTextarea>
                <Text
                  typography="footNote12Regular"
                  color="textTertiary"
                  style={{position: 'absolute', bottom: 8, right: 8}}>
                  {message.length} / 5000
                </Text>
              </RichView>
            )}
          </Flex>
          <Flex gap={16} direction="column">
            {presets.map((preset, index) => (
              <Preset
                key={index + '_preset'}
                preset={preset}
                onClick={preset => {
                  insertAtCursor(` ${preset}`);
                }}
              />
            ))}
          </Flex>
          <TextArea
            value={replaceValueToExample(message)}
            disabled
            size="large"
            style={{backgroundColor: colors.bgPrimary}}
            label="Предпросмотр"
          />
        </Content>
        <Content gap="24px">
          <Text typography="title18">Методы отправки рассылки</Text>
          <Flex direction="column" gap={16}>
            <Select
              value={method}
              onChange={setMethod}
              label="Способ рассылки"
              dropdownPosition="top"
              data={messageSampleMethods}
            />
            <Select
              value={time}
              onChange={setTime}
              label="Момент рассылки"
              dropdownPosition="top"
              data={messageSampleTimes}
            />
            <Select
              value={event}
              onChange={setEvent}
              label="Событие"
              dropdownPosition="top"
              data={messageSampleEvents}
            />
            <Select
              value={bookingStatus}
              onChange={setBookingStatus}
              label="Статус записи"
              dropdownPosition="top"
              data={messageSampleBookingsStatus}
            />
          </Flex>
        </Content>
        <Flex style={{marginTop: 8}} gap={16}>
          <Button
            size="large"
            style={{
              flex: 1,
              borderColor: colors.dangerPrimary,
              color: colors.dangerPrimary,
            }}
            variant="outline"
            onClick={() => {
              deleteMessage({
                variables: {deleteMessageSampleId: id!},
              })
                .then(() => {
                  showAlert('success', 'шаблон удален');
                  navigate(-1);
                })
                .catch((e: ApolloError) => showAlert('error', e.message));
            }}
            leftIcon={<Trash color={colors.dangerPrimary} />}>
            Удалить
          </Button>
          <Button
            style={{flex: 1}}
            disabled={updateLoading}
            loading={updateLoading}
            onClick={() => {
              if (name.length === 0) {
                setNameError('Обязательное поле');
                return;
              }
              if (message.length === 0 || message.length > 1000) {
                setMessageError(
                  message.length === 0
                    ? 'Заполните поле'
                    : 'Превышен лимит символов',
                );
                return;
              }
              const input: UpdateMessageSample = {
                booking_status: bookingStatus,
                id: id!,
                event: event,
                message: formatted(message),
                method: method,
                name,
                time,
                is_active: enable,
              };
              updateMessage({variables: {input}})
                .then(() => {
                  showAlert('success', 'шаблон изменен');
                  navigate(-1);
                })
                .catch((e: ApolloError) => showAlert('error', e.message));
            }}
            size="large">
            Сохранить
          </Button>
        </Flex>
      </Wrapper>
    </Layout>
  );
};
