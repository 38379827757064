import React, {useState} from 'react';
import styled from 'styled-components';
import {Flex} from 'shared/ui/Flex';
import {device} from 'shared/device';
import {Button} from 'shared/ui/Button';
import {TextInput} from 'shared/ui/TextInput';
import {Search as SearchIcon} from 'shared/icons/Search';
import {Text} from 'shared/ui/Text';
import {Plus} from 'shared/icons/Plus';
import {Modal} from 'shared/ui/Modal';
import {useNavigate} from 'react-router-dom';
import {IconButton} from 'shared/ui/IconButton';
import {useColors, useResize} from 'shared/lib/hooks';
import {CompanyRoles} from 'shared/__generated__/graphql';
import {FilterBtn} from 'shared/ui/FilterButton';
import DotsVertical from 'shared/icons/DotsVertical';
import {useAppSelector} from 'shared/store';
import {Divider} from 'shared/ui/Divider';
import CsvDownloader from 'react-csv-downloader';
import {OptionsType} from 'shared/ui/Table';
import {client} from 'shared/config/apollo';
import {GET_COMPANY_EMPLOYEES} from 'entities/employees';
import {Loader} from 'shared/icons/Loader';
import {getDataForExport} from '..';
import {getRating} from 'shared/lib/utils';

type FilterType = {
  enabled?: boolean;
  role?: CompanyRoles;
  professions?: string[];
};

type Props = {
  search?: string;
  page?: number;
  onChangeText?: (value: string) => void;
  onChangePage?: (value: number) => void;
  options: OptionsType;
  setOptions: React.Dispatch<React.SetStateAction<OptionsType>>;
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
  editPermission: boolean;
  onFilterChange: () => void;
  total: number;
};

const Header = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  @media ${device.mobile} {
    margin: 24px 16px 0px;
  }
`;

const PopupWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const AddButton = styled(IconButton)`
  background-color: ${({theme}) => theme.mainPrimary};
  position: absolute;
  top: 16px;
  right: 16px;
`;

const ButtonSelect = styled.button<{checked?: boolean}>`
  pointer-events: all;
  cursor: pointer;
  display: flex;
  padding: 0 16px;
  align-items: center;
  height: 44px;
  width: 284px;
  background-color: ${({checked, theme}) =>
    checked ? theme.bgSecondary : 'transparent'};
  border: none;
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
`;

const CSVButton = styled(CsvDownloader)`
  pointer-events: all;
  cursor: pointer;
  display: flex;
  padding: 0 16px;
  align-items: center;
  height: 56px;
  width: 284px;
  background-color: transparent;
  justify-content: space-between;
  border: none;
  &:hover {
    background-color: ${({theme}) => theme.bgSecondary};
  }
  text-decoration-line: none;
`;

export const Setting = ({
  search,
  filter,
  onChangeText,
  setFilter,
  editPermission,
  total,
  onFilterChange,
}: Props) => {
  const color = useColors();
  const navigate = useNavigate();
  const {isMobile} = useResize();
  const companyId = useAppSelector(state => state.company.data?.id ?? '');
  const [exportLoading, setExportLoading] = useState(false);
  const [modalSetting, setModalSetting] = React.useState(false);
  const [modalFilter, setModalFilter] = React.useState(false);
  const username = useAppSelector(state => state.company.data?.username);

  return (
    <Header direction="row">
      <Flex gap={8}>
        <TextInput
          height={54}
          width="320px"
          variant="default"
          borderRadius={14}
          onChange={onChangeText}
          value={search}
          backgroundColor={color.bgPrimary}
          placeholder="Введите имя или телефон"
          leftElement={<SearchIcon color={color.textTertiary} />}
        />
        <PopupWrapper>
          <FilterBtn
            size="medium"
            style={{height: 56}}
            indicator={[filter.role ? 1 : 0, filter.enabled ? 1 : 0].reduce(
              (acc, cur) => acc + cur,
              0,
            )}
            onClick={() => {
              setModalFilter(true);
            }}
          />
          <Modal
            style={{
              left: 'auto',
              right: 'auto',
              top: 64,
              paddingLeft: 0,
              paddingRight: 0,
              gap: 8,
            }}
            visible={modalFilter}
            onClose={() => setModalFilter(false)}>
            <Text mb={8} style={{marginLeft: 24}} color="textSecondary">
              Роль
            </Text>
            <ButtonSelect
              style={{paddingLeft: 24, paddingRight: 24}}
              checked={filter.role === undefined}
              onClick={() => {
                setFilter(prev => ({
                  ...prev,
                  role: undefined,
                }));
                onFilterChange();
                setModalFilter(false);
              }}>
              <Text style={{pointerEvents: 'all', cursor: 'pointer'}}>Все</Text>
            </ButtonSelect>
            <ButtonSelect
              style={{paddingLeft: 24, paddingRight: 24}}
              checked={filter.role === CompanyRoles.Participant}
              onClick={() => {
                setFilter(prev => ({
                  ...prev,
                  role: CompanyRoles.Participant,
                }));
                onFilterChange();
                setModalFilter(false);
              }}>
              <Text style={{pointerEvents: 'all', cursor: 'pointer'}}>
                Сотрудник
              </Text>
            </ButtonSelect>
            <ButtonSelect
              style={{paddingLeft: 24, paddingRight: 24}}
              checked={filter.role === CompanyRoles.Admin}
              onClick={() => {
                setFilter(prev => ({
                  ...prev,
                  role: CompanyRoles.Admin,
                }));
                onFilterChange();
                setModalFilter(false);
              }}>
              <Text style={{pointerEvents: 'all', cursor: 'pointer'}}>
                Администратор
              </Text>
            </ButtonSelect>
            <ButtonSelect
              style={{paddingLeft: 24, paddingRight: 24}}
              checked={filter.role === CompanyRoles.Owner}
              onClick={() => {
                setFilter(prev => ({
                  ...prev,
                  role: CompanyRoles.Owner,
                }));
                onFilterChange();
                setModalFilter(false);
              }}>
              <Text style={{pointerEvents: 'all', cursor: 'pointer'}}>
                Владелец
              </Text>
            </ButtonSelect>
            <Divider style={{marginLeft: 24, marginRight: 24}} mt={16} />
            <Text mt={16} mb={8} style={{marginLeft: 24}} color="textSecondary">
              Статус активности
            </Text>
            <ButtonSelect
              style={{paddingLeft: 24, paddingRight: 24}}
              checked={filter.enabled === undefined}
              onClick={() => {
                setFilter(prev => ({
                  ...prev,
                  enabled: undefined,
                }));
                onFilterChange();
                setModalFilter(false);
              }}>
              <Text style={{pointerEvents: 'all', cursor: 'pointer'}}>Все</Text>
            </ButtonSelect>
            <ButtonSelect
              style={{paddingLeft: 24, paddingRight: 24}}
              checked={filter.enabled}
              onClick={() => {
                setFilter(prev => ({
                  ...prev,
                  enabled: true,
                }));
                onFilterChange();
                setModalFilter(false);
              }}>
              <Text style={{pointerEvents: 'all', cursor: 'pointer'}}>
                Активный
              </Text>
            </ButtonSelect>
            <ButtonSelect
              style={{paddingLeft: 24, paddingRight: 24}}
              checked={filter.enabled === false}
              onClick={() => {
                setFilter(prev => ({
                  ...prev,
                  enabled: false,
                }));
                onFilterChange();
                setModalFilter(false);
              }}>
              <Text style={{pointerEvents: 'all', cursor: 'pointer'}}>
                Неактивный
              </Text>
            </ButtonSelect>
          </Modal>
        </PopupWrapper>
      </Flex>
      <Flex gap={8}>
        {!isMobile && (
          <PopupWrapper>
            <IconButton
              onClick={() => setModalSetting(true)}
              size={48}
              style={{
                borderRadius: 16,
                pointerEvents: 'all',
                backgroundColor: color.bgPrimary,
              }}>
              <DotsVertical />
            </IconButton>
            <Modal
              style={{
                right: 0,
                left: 'auto',
                top: 56,
                paddingLeft: 0,
                paddingRight: 0,
              }}
              visible={modalSetting}
              onClose={() => setModalSetting(false)}>
              <Flex gap={8} direction="column" justifyContent="space-between">
                <CSVButton
                  disabled={exportLoading || !total}
                  filename={'Список сотрудников.csv'}
                  datas={async () => {
                    setExportLoading(true);
                    return await client
                      .query({
                        query: GET_COMPANY_EMPLOYEES,
                        variables: {
                          companyId,
                          first: total,
                        },
                      })
                      .then(({data}) => {
                        const resDataEmployee = data.companyEmployees?.data.map(
                          item => ({
                            id: item.id,
                            name: item.name,
                            surname: item.surname,
                            invite_status: item.invite_status,
                            serviceLenght:
                              item.services?.paginatorInfo.total ?? 0,
                            phone: item.phone ? `+${item.phone}` : '',
                            rayting: getRating(item),
                          }),
                        );
                        return getDataForExport(resDataEmployee ?? []);
                      })
                      .finally(() => {
                        setExportLoading(false);
                        setModalSetting(false);
                      });
                  }}>
                  <Text
                    style={{
                      pointerEvents: 'all',
                      cursor: 'pointer',
                    }}>
                    Экспортировать в Excel
                  </Text>
                  {exportLoading && <Loader size={16} />}
                </CSVButton>
                {editPermission && (
                  <ButtonSelect
                    style={{marginRight: 0, marginLeft: 0, height: 56}}
                    onClick={() => {
                      navigate(`/${username}/order-employees`);
                      setModalSetting(false);
                    }}>
                    <Text style={{pointerEvents: 'all', cursor: 'pointer'}}>
                      Порядок сотрудников
                    </Text>
                  </ButtonSelect>
                )}
              </Flex>
            </Modal>
          </PopupWrapper>
        )}

        {!editPermission ? null : isMobile ? (
          <AddButton
            size={40}
            onClick={() => navigate(`/${username}/add-employee`)}>
            <Plus />
          </AddButton>
        ) : (
          <Button
            size="medium"
            style={{
              paddingLeft: 32,
              paddingRight: 32,
            }}
            onClick={() => navigate(`/${username}/add-employee`)}
            variant="filled"
            leftIcon={<Plus size={20} />}>
            Добавить сотрудника
          </Button>
        )}
      </Flex>
    </Header>
  );
};
