import React, {useEffect, useRef, useState} from 'react';
import {Plus} from 'shared/icons/Plus';
import {Search} from 'shared/icons/Search';
import {useColors, useDebounce, useEmployeePermission} from 'shared/lib/hooks';
import {Button} from 'shared/ui/Button';
import {Content} from 'shared/ui/Content';
import {Flex} from 'shared/ui/Flex';
import {OptionsType, Table} from 'shared/ui/Table';
import {Text} from 'shared/ui/Text';
import {TextInput} from 'shared/ui/TextInput';
import {EmptyStateProductsCategory} from './EmptyState';
import {ICategoryEditModalProps} from 'entities/products';
import {Slide} from 'shared/ui/Slide';
import {ApolloError, useLazyQuery, useMutation} from '@apollo/client';
import {
  DELETE_MANY_PRODUCTS_CATEGORY,
  GET_PRODUCTS,
  PRODUCTS_CATEGORIES,
} from 'entities/products';
import {useAppSelector} from 'shared/store';
import {SkeletonProductCategory} from './Skeleton';
import {client} from 'shared/config/apollo';
import {FooterAction} from 'shared/ui/FooterAction';
import {showAlert} from 'shared/ui/Alert';
import {FilterBtn} from 'shared/ui/FilterButton';
import styled from 'styled-components';
import {List} from 'shared/ui/List';
import {CheckBox} from 'shared/ui/CheckBox';

export const Categories = ({
  visible,
  onOpenModal,
}: {
  visible: boolean;
  onOpenModal: (value?: ICategoryEditModalProps) => void;
}) => {
  const colors = useColors();
  const [search, setSearch] = useState('');
  const companyId = useAppSelector(state => state.company.data?.id);
  const [filterCategory, setFilterCategory] = useState<string[]>([]);
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 40,
    total: 0,
    count: 0,
  });
  const [getCategories, {data, loading}] = useLazyQuery(PRODUCTS_CATEGORIES, {
    onCompleted: res => {
      setOptions(prev => ({
        ...prev,
        total: res.productsCategory?.paginatorInfo.total,
        count: res.productsCategory?.paginatorInfo.count,
        lastPage: res.productsCategory?.paginatorInfo.lastPage,
      }));
    },
  });
  const {edit_products_permission} = useEmployeePermission(data);
  const categories = data?.productsCategory?.data ?? [];
  const cacheData = client.readQuery({
    query: PRODUCTS_CATEGORIES,
    variables: {
      companyId: companyId!,
      page: 1,
      first: options.first,
    },
  });
  const cacheProducts = client.readQuery({
    query: GET_PRODUCTS,
    variables: {
      companyId: companyId!,
      first: options.first,
      page: 1,
    },
  });
  const cacheCategories = cacheData?.productsCategory?.data ?? [];
  const products = cacheProducts?.getProducts?.data ?? [];
  const [selections, setSelections] = useState<number[]>([]);
  const [modalFilter, setModalFilter] = useState(false);
  const filterRef = useRef<HTMLDivElement>(null);

  const [deleteManyCategories, {loading: loadingManyDelete}] = useMutation(
    DELETE_MANY_PRODUCTS_CATEGORY,
    {
      refetchQueries: [
        {
          query: PRODUCTS_CATEGORIES,
          variables: {
            companyId: companyId!,
            page: 1,
            first: options.first,
          },
        },
      ],
    },
  );
  const filterData = cacheCategories.map(item => ({
    label: item.name,
    value: item.id,
  }));

  const searchDebounce = useDebounce((query: string) => {
    getCategories({
      variables: {
        companyId: companyId!,
        page: 1,
        first: options.first,
        name: query.length ? query : undefined,
        productsCategoryId:
          filterCategory.length === 0 ? undefined : filterCategory,
      },
    });
  }, 400);

  useEffect(() => {
    if (visible) {
      getCategories({
        variables: {
          companyId: companyId!,
          page: 1,
          first: options.first,
          productsCategoryId:
            filterCategory !== null && filterCategory.length > 0
              ? filterCategory
              : undefined,
        },
      });
    }
  }, [companyId, filterCategory, getCategories, options.first, visible]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target as Node)
      ) {
        setModalFilter(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [filterRef]);

  return (
    <Slide style={{height: '100%'}}>
      <Flex direction="column" flex={1} style={{position: 'relative'}}>
        <Flex
          flex={1}
          style={{padding: '4px 24px 24px 24px'}}
          direction="column"
          gap={24}>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex direction="row" justifyContent="space-between" gap={8}>
              <TextInput
                variant="default"
                borderRadius={14}
                backgroundColor={colors.bgPrimary}
                wrapperStyle={{flexShrink: 1, flexWrap: 'wrap'}}
                leftElement={<Search color={colors.textTertiary} />}
                style={{flexShrink: 1}}
                placeholder="Поиск"
                height={54}
                value={search}
                onChange={value => {
                  setSearch(value);
                  searchDebounce(value);
                }}
                width="352px"
              />
              <FilterContainer ref={filterRef}>
                <FilterBtn
                  indicator={filterCategory.length}
                  onClick={() => setModalFilter(true)}
                  style={{
                    height: 56,
                    color:
                      filterCategory.length > 0
                        ? colors.mainPrimary
                        : colors.textPrimary,
                  }}
                />
                <CustomDropdown isOpen={modalFilter}>
                  <List
                    data={filterData}
                    gap={8}
                    renderItem={item => (
                      <CheckBox
                        title={item.label}
                        hover
                        style={{padding: '20px 16px', gap: 10}}
                        checked={filterCategory?.includes(item.value)}
                        onChange={() => {
                          if (filterCategory.includes(item.value)) {
                            setFilterCategory(
                              filterCategory.filter(
                                item2 => item2 !== item.value,
                              ),
                            );
                          } else {
                            setFilterCategory([...filterCategory, item.value]);
                          }
                        }}
                      />
                    )}
                  />
                </CustomDropdown>
              </FilterContainer>
            </Flex>
            {edit_products_permission && (
              <Button onClick={() => onOpenModal()}>
                <Plus size={20} color={colors.white} />
                Создать категорию
              </Button>
            )}
          </Flex>
          <Flex direction="column" gap={categories.length > 0 ? 24 : 32}>
            {loading ? (
              <SkeletonProductCategory />
            ) : categories.length === 0 ? (
              <EmptyStateProductsCategory />
            ) : (
              <Content>
                <Table
                  titles={[
                    'Название категории',
                    'Родительская категория',
                    'Количество подкатегорий',
                    'Количество наименований товаров',
                  ]}
                  rows={categories.map(item => [
                    item.name,
                    item.parent ? item.parent.name : '-',
                    cacheCategories.reduce((acc, cur) => {
                      if (cur.parent?.id === item.id) {
                        return acc + 1;
                      }
                      return acc;
                    }, 0) > 0
                      ? cacheCategories.reduce((acc, cur) => {
                          if (cur.parent?.id === item.id) {
                            return acc + 1;
                          }
                          return acc;
                        }, 0)
                      : '-',
                    <Text
                      key={'products_length_' + item.id}
                      color="mainPrimary"
                      style={{cursor: 'pointer'}}>
                      {products.reduce((acc, cur) => {
                        if (cur?.products_category?.id === item.id) {
                          return acc + 1;
                        }
                        return acc;
                      }, 0)}
                    </Text>,
                  ])}
                  options={options}
                  setOptions={setOptions}
                  onClick={index => {
                    if (edit_products_permission) {
                      onOpenModal({
                        id: categories[index].id,
                        name: categories[index].name,
                        parent: categories[index].parent?.id,
                      });
                    } else {
                      showAlert(
                        'warning',
                        'У вас нет прав доступа на создание/редактирование/удаление категории',
                      );
                    }
                  }}
                  selections={selections}
                  onSelectionChange={
                    edit_products_permission ? setSelections : undefined
                  }
                />
              </Content>
            )}
          </Flex>
        </Flex>
        {selections.length > 0 && (
          <FooterAction
            popupStyle={{
              left: 'calc(300% / 2)',
            }}
            allICount={options.count}
            selectedCount={selections.length}
            disabled={selections.length === 0}
            editPermission={true}
            onDelete={async () => {
              try {
                await deleteManyCategories({
                  variables: {
                    deleteManyProductsCategoryId: categories
                      .filter((_, index) => selections.includes(index))
                      .map(item => item.id),
                  },
                });
                setSelections([]);
              } catch (e) {
                if (e instanceof ApolloError) {
                  showAlert('error', e.message);
                }
              }
            }}
            deleteLoading={loadingManyDelete}
            title="Удалить категории"
            text="Вы уверены, что хотите удалить выбранные товары?"
          />
        )}
      </Flex>
    </Slide>
  );
};

const CustomDropdown = styled.div<{
  isOpen: boolean;
}>`
  position: absolute;
  width: max-content;
  max-width: 540px;
  border-radius: 18px;
  background-color: ${props => props.theme.bgPrimary};
  border: 1px solid ${props => props.theme.borderPrimary};
  z-index: 100;
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  gap: 8px;
  max-height: 300px;
  overflow: auto;
  padding: 16px 0;
`;

const FilterContainer = styled.div``;
