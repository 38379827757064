import {gql} from 'shared/__generated__';
import {
  salaryType,
  salaryPeriod,
  salaryTime,
  type SalarySelectedServices,
  type SalaryData,
} from './lib/utils';
import {TextInputSwitch} from './ui/TextInputSwitch';
import {SalaryInputSwitch} from './ui/SalaryInputSwitch';
import {TitleContent} from './ui/TitleContent';
import {TabsControl} from './ui/TabsControl';

const GET_RULES_SALARIES = gql(`
  query CompanySalaryRules($companyId: String!, $page: Int, $first: Int!) {
    companySalaryRules(company_id: $companyId, page: $page, first: $first) {
      paginatorInfo {
        currentPage
        total
        count
      }
      data {
        name
        salary_value
        salary_type
        employees {
          id
        }
        service_salary_value
        service_salary_type
        id
      }
    }
  }
`);

export {
  GET_RULES_SALARIES,
  salaryType,
  salaryPeriod,
  salaryTime,
  type SalarySelectedServices,
  type SalaryData,
  TabsControl,
  TextInputSwitch,
  SalaryInputSwitch,
  TitleContent,
};
