import React, {useState} from 'react';
import {Grow} from 'shared/ui/Grow';
import {EmployeesHeader} from './ui/Header';
import {ApolloError, useMutation, useQuery} from '@apollo/client';
import {EmployeesTable} from './ui/EmployeesTable';
import {EmptyState} from './ui/EmptyState';
import {EmployeesSkeleton} from './ui/Skeleton';
import {Setting} from './ui/Setting';
import {useAppSelector} from 'shared/store';
import {getName, getRating} from 'shared/lib/utils';
import {
  CompanyRoles,
  QueryCompanyEmployeesOrderByColumn,
  SortOrder,
} from 'shared/__generated__/graphql';
import {styled} from 'styled-components';
import {OptionsType, TableSortType} from 'shared/ui/Table';
import {showAlert} from 'shared/ui/Alert';
import {FooterAction} from 'shared/ui/FooterAction';
import {DELETE_EMPLOYEE, GET_COMPANY_EMPLOYEES} from 'entities/employees';
import {useDebounce, useEmployeePermission} from 'shared/lib/hooks';

type FilterType = {
  enabled?: boolean;
  role?: CompanyRoles;
  professions?: string[];
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const getDataForExport = (
  data: {
    name: string | null | undefined;
    surname: string | null | undefined;
    phone: string | null | undefined;
    serviceLenght: number;
    rayting: number;
    invite_status: string | null | undefined;
  }[],
) =>
  data.map(({name, surname, phone, serviceLenght, rayting, invite_status}) => ({
    Имя: getName(name, surname, 'employee'),
    Телефон: phone ?? '',
    Количество_услуг: `${serviceLenght}`,
    Рейтинг: `${rayting}`,
    Статус:
      invite_status === 'accepted'
        ? 'Активный'
        : invite_status === 'pending'
        ? 'Отправлено'
        : 'Не активный',
  }));

const ORDER_COLUMS: Array<QueryCompanyEmployeesOrderByColumn> = [
  QueryCompanyEmployeesOrderByColumn.Name,
  QueryCompanyEmployeesOrderByColumn.Phone,
  QueryCompanyEmployeesOrderByColumn.Rating,
  QueryCompanyEmployeesOrderByColumn.InviteStatus,
];

export const Employees = () => {
  const companyId = useAppSelector(state => state.company.data?.id ?? '');
  const [sort, setSort] = useState<TableSortType>();
  const [options, setOptions] = useState<OptionsType>({
    page: 1,
    first: 10,
    total: 0,
    count: 0,
    lastPage: 1,
  });
  const [filter, setFilter] = useState<FilterType>({
    enabled: undefined,
    role: undefined,
    professions: undefined,
  });
  const [search, setSearch] = useState('');
  const [selects, setSelects] = useState<{id: string; index: number}[]>([]);
  const [deleteEmployee, {loading: deleteLoading}] = useMutation(
    DELETE_EMPLOYEE,
    {
      refetchQueries: ['CompanyEmployees'],
    },
  );

  const {data, loading, refetch} = useQuery(GET_COMPANY_EMPLOYEES, {
    variables: {
      companyId,
      first: options.first,
      page: options.page,
      enabled: filter.enabled,
      profession: filter.professions,
      role: filter.role,
      orderBy: sort
        ? [
            {
              column:
                ORDER_COLUMS[sort.index > 2 ? sort.index - 1 : sort.index],
              order: sort ? sort.order : SortOrder.Desc,
            },
          ]
        : undefined,
    },
    onCompleted: res =>
      setOptions(prev => ({
        ...prev,
        total: res.companyEmployees?.paginatorInfo.total,
        count: res.companyEmployees?.paginatorInfo.count,
        lastPage: res.companyEmployees?.paginatorInfo.lastPage,
      })),
  });
  const {edit_employees_permission, employees_info_permission} =
    useEmployeePermission(data);
  const employees =
    data?.companyEmployees?.data.map(item => ({
      id: item.id,
      name: item.name,
      surname: item.surname,
      avatar: item.avatar ?? undefined,
      invite_status: item.invite_status,
      serviceLenght: item.services?.paginatorInfo.total ?? 0,
      enabled: item.enabled,
      phone: item.phone,
      rayting: getRating(item),
      is_online: item.user?.is_online ?? false,
    })) ?? [];

  const handleSelectionChange = (indexes: number[]) => {
    const newSelectedData = indexes.map(
      index => ({id: employees?.[index].id, index}) ?? [],
    );
    setSelects(newSelectedData);
  };

  const onDeleteEmployees = () => {
    selects.forEach(item => {
      deleteEmployee({
        variables: {
          deleteEmployeeId: item.id,
        },
      }).catch((e: ApolloError) => showAlert('error', e.message));
    });
    setSelects([]);
  };

  const selectedEmployees = employees.filter(employee =>
    selects.map(item => item.id).includes(employee.id),
  );
  const refetchDebounce = useDebounce(async (query: string) => {
    await refetch({
      query,
    });
  }, 300);

  return (
    <Wrapper>
      <Grow style={{padding: 24, gap: 24}}>
        <EmployeesHeader number={options.total} />
        <Setting
          total={options.total ?? 0}
          editPermission={edit_employees_permission}
          search={search}
          onChangeText={text => {
            setSearch(text);
            refetchDebounce(text);
            setSelects([]);
          }}
          options={options}
          setOptions={setOptions}
          filter={filter}
          setFilter={setFilter}
          onFilterChange={() => setSelects([])}
        />
        {loading ? (
          <EmployeesSkeleton />
        ) : employees?.length ? (
          <EmployeesTable
            sort={sort}
            setSort={setSort}
            permission={employees_info_permission}
            employees={employees}
            options={options}
            setOptions={setOptions}
            onSelectionChange={handleSelectionChange}
            selections={selects.map(item => item.index)}
          />
        ) : (
          <EmptyState
            filtered={
              !!search ||
              filter.enabled !== undefined ||
              !!filter.professions ||
              !!filter.role
            }
          />
        )}
      </Grow>
      <FooterAction
        exportData={getDataForExport(selectedEmployees)}
        filename="Список выбранных сотрудников"
        exportable
        editPermission={edit_employees_permission}
        allICount={options.count}
        selectedCount={selects.length}
        disabled={selects.length === 0}
        onDelete={onDeleteEmployees}
        deleteLoading={deleteLoading}
        title="Удалить сотрудников"
        text="Вы уверены, что хотите удалить выбранных сотрудников?"
      />
    </Wrapper>
  );
};
